import { Button } from '@chakra-ui/react';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import {
  Page,
  Document,
  StyleSheet,
  Svg,
  Path,
  Rect,
  Circle,
  Polygon,
  Polyline,
  pdf,
} from '@react-pdf/renderer';
import React, { useState } from 'react';
import SparkelIcon from 'src/components/common/icon/SparkelIcon';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    padding: 50,
  },
  svgContainer: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

export interface SvgPathData {
  d?: string;
  stroke?: string;
  fill?: string;
  strokeOpacity?: number;
  fillOpacity?: number;
  strokeWidth?: number;
  [key: string]: string | number | undefined;
}

interface SvgElementData {
  type: string;
  attributes: Record<string, string | number | undefined>;
  styles: Partial<SvgPathData>;
}

const parseRgba = (rgba: string) => {
  const match = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([.\d]*)?\)/);
  if (!match) return { color: rgba, opacity: 1 };

  const [, r, g, b, a] = match;
  return {
    color: `rgb(${r},${g},${b})`,
    opacity: a ? parseFloat(a) : 1,
  };
};

const extractClassStyles = (classNames: string[]) => {
  const styles: Partial<SvgPathData> = {};

  classNames.forEach((className) => {
    if (className === 'css-0') return;

    const element = document.querySelector(`.${className}`) as Element;
    if (!element) return;

    const style = window.getComputedStyle(element);
    applyStylesFromComputedStyle(style, styles);
  });

  return styles;
};

const applyStylesFromComputedStyle = (
  style: CSSStyleDeclaration,
  styles: Partial<SvgPathData>
) => {
  if (style.stroke && style.stroke !== 'none') styles.stroke = style.stroke;
  if (style.fill) {
    if (style.fill === 'transparent' || style.fill === 'none') {
      styles.fill = 'none';
      styles.fillOpacity = 0;
    } else {
      const { color, opacity } = parseRgba(style.fill);
      styles.fill = color;
      styles.fillOpacity = opacity;
    }
  }
  if (style.strokeOpacity)
    styles.strokeOpacity = parseFloat(style.strokeOpacity);
  if (style.fillOpacity) styles.fillOpacity = parseFloat(style.fillOpacity);
  if (style.strokeWidth) styles.strokeWidth = parseFloat(style.strokeWidth);
};

const extractInlineStyles = (style: string) => {
  return style.split(';').reduce((acc, style) => {
    const [key, value] = style.split(':').map((s) => s.trim());
    if (key && value) {
      applyInlineStyle(key, value, acc);
    }
    return acc;
  }, {} as Partial<SvgPathData>);
};

const applyInlineStyle = (
  key: string,
  value: string,
  styles: Partial<SvgPathData>
) => {
  if (key === 'stroke' && value !== 'none') styles.stroke = value;
  if (key === 'fill') {
    if (value === 'transparent' || value === 'none') {
      styles.fill = 'none';
      styles.fillOpacity = 0;
    } else {
      styles.fill = value;
    }
  }
  if (key === 'stroke-opacity') styles.strokeOpacity = parseFloat(value);
  if (key === 'fill-opacity') styles.fillOpacity = parseFloat(value);
  if (key === 'stroke-width') styles.strokeWidth = parseFloat(value);
};

export const captureSvgData = (svgElement: SVGSVGElement) => {
  const clone = svgElement.cloneNode(true) as SVGSVGElement;
  const bbox = svgElement.getBBox();
  clone.setAttribute(
    'viewBox',
    `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
  );
  clone.setAttribute('width', `${bbox.width}`);
  clone.setAttribute('height', `${bbox.height}`);
  return new XMLSerializer().serializeToString(clone);
};

const extractStyle = (element: Element): Partial<SvgPathData> => {
  const style = element.getAttribute('style') || '';
  const classNames = element.getAttribute('class')?.split(' ') || [];

  const classStyles = extractClassStyles(classNames);
  const inlineStyles = extractInlineStyles(style);

  const combinedStyles = { ...classStyles, ...inlineStyles };
  if (!combinedStyles.fill && combinedStyles.stroke) {
    combinedStyles.fill = 'none';
  }

  return combinedStyles;
};

const extractAttributes = (element: Element) => {
  const attributes: Record<string, string | number | undefined> = {};
  for (let i = 0; i < element.attributes.length; i++) {
    const attr = element.attributes[i];
    attributes[attr.name] = attr.value;
  }
  return attributes;
};

const applyGroupStylesToChildren = (
  parent: Element,
  inheritedElement: SvgElementData | null = null
): SvgElementData[] => {
  const elements: SvgElementData[] = [];
  const children = Array.from(parent.children);

  const groupStyles = extractStyle(parent);
  const combinedStyles = { ...inheritedElement?.styles, ...groupStyles };

  children.forEach((child) => {
    const childStyles = extractStyle(child);
    const finalStyles = { ...combinedStyles, ...childStyles };
    const attributes = extractAttributes(child);

    const elementData: SvgElementData = {
      type: child.tagName,
      attributes,
      styles: finalStyles,
    };

    elements.push(elementData);

    if (child.tagName === 'g' || child.tagName === 'svg') {
      elements.push(...applyGroupStylesToChildren(child, elementData));
    }
  });

  return elements;
};

const extractSvgData = (svgString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'image/svg+xml');

  const svgElement = doc.querySelector('svg');
  if (!svgElement) {
    console.error('No SVG element found in provided string.');
    return { elements: [], viewBox: '0 0 100 100' };
  }

  const viewBox = svgElement.getAttribute('viewBox') || '0 0 100 100';

  const rootElementData: SvgElementData = {
    type: 'svg',
    attributes: {
      viewBox,
      width: svgElement.getAttribute('width') || '100%',
      height: svgElement.getAttribute('height') || '100%',
    },
    styles: {},
  };

  const elements = applyGroupStylesToChildren(svgElement, rootElementData);

  return { elements, viewBox };
};

// eslint-disable-next-line complexity
const renderSvgElement = (element: SvgElementData, index: number) => {
  const { type, attributes, styles } = element;

  const effectiveStyles = {
    ...styles,
    fillOpacity:
      styles.fill === 'none' ||
      styles.fill === 'transparent' ||
      styles.fillOpacity === 0
        ? 0.0001 // Workaround for fillOpacity not working with 0
        : styles.fillOpacity,
  };

  switch (type) {
    case 'path':
      return (
        <Path key={index} d={attributes.d as string} {...effectiveStyles} />
      );

    case 'rect':
      if (attributes.width !== undefined && attributes.height !== undefined) {
        return (
          <Rect
            key={index}
            x={attributes.x || 0}
            y={attributes.y || 0}
            width={attributes.width as number}
            height={attributes.height as number}
            {...effectiveStyles}
          />
        );
      }
      break;

    case 'circle':
      if (
        attributes.cx !== undefined &&
        attributes.cy !== undefined &&
        attributes.r !== undefined
      ) {
        return (
          <Circle
            key={index}
            cx={attributes.cx as number}
            cy={attributes.cy as number}
            r={attributes.r as number}
            {...effectiveStyles}
          />
        );
      }
      break;

    case 'polygon':
      if (attributes.points) {
        return (
          <Polygon
            key={index}
            points={attributes.points as string}
            {...effectiveStyles}
          />
        );
      }
      break;

    case 'polyline':
      if (attributes.points) {
        return (
          <Polyline
            key={index}
            points={attributes.points as string}
            {...effectiveStyles}
          />
        );
      }
      break;

    default:
      return null;
  }

  return null;
};

const PdfShapeRenderer = ({ svgString }: { svgString: string }) => {
  const { elements, viewBox } = extractSvgData(svgString);

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <Svg style={styles.svgContainer} viewBox={viewBox}>
          {elements.map(renderSvgElement)}
        </Svg>
      </Page>
    </Document>
  );
};

const ExportButton = ({
  svgRef,
}: {
  svgRef: React.RefObject<SVGSVGElement>;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line complexity
  const handleExportClick = async () => {
    console.log('Exporting to PDF');
    if (svgRef.current) {
      setIsLoading(true);

      const svgElement = svgRef.current;

      // Capture the original attributes before making any changes
      const originalAttributes = {
        viewBox: svgElement.getAttribute('viewBox'),
        width: svgElement.getAttribute('width'),
        height: svgElement.getAttribute('height'),
        transform: svgElement.getAttribute('transform'),
      };

      // Calculate the bounding box of the SVG content
      const bbox = svgElement.getBBox();

      if (bbox.width === 0 || bbox.height === 0) {
        console.error('Bounding box dimensions are zero, cannot scale');
        setIsLoading(false);
        return;
      }

      // PDF page dimensions
      const pageWidth = 841.89; // A4 width in points (72 points/inch)
      const pageHeight = 595.28; // A4 height in points
      const padding = 50;

      // Calculate scale factor to fit SVG content within PDF page with padding
      const scaleX = (pageWidth - 2 * padding) / bbox.width;
      const scaleY = (pageHeight - 2 * padding) / bbox.height;
      const scale = Math.min(scaleX, scaleY); // Uniform scaling

      // Calculate the offset to center the content in the PDF page
      const offsetX =
        padding + (pageWidth - 2 * padding - bbox.width * scale) / 2;
      const offsetY =
        padding + (pageHeight - 2 * padding - bbox.height * scale) / 2;

      // Apply scaling and translation to the SVG
      svgElement.setAttribute(
        'viewBox',
        `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
      );
      svgElement.setAttribute('width', `${bbox.width * scale}`);
      svgElement.setAttribute('height', `${bbox.height * scale}`);

      if (!isNaN(offsetX) && !isNaN(offsetY) && !isNaN(scale)) {
        svgElement.setAttribute(
          'transform',
          `translate(${offsetX}, ${offsetY}) scale(${scale})`
        );
      } else {
        console.error('Invalid transformations detected');
        setIsLoading(false);
        return;
      }

      // Generate the SVG string
      const svgString = new XMLSerializer().serializeToString(svgElement);

      // Restore the original attributes
      if (originalAttributes.viewBox !== null) {
        svgElement.setAttribute('viewBox', originalAttributes.viewBox);
      } else {
        svgElement.removeAttribute('viewBox');
      }

      if (originalAttributes.width !== null) {
        svgElement.setAttribute('width', originalAttributes.width);
      } else {
        svgElement.removeAttribute('width');
      }

      if (originalAttributes.height !== null) {
        svgElement.setAttribute('height', originalAttributes.height);
      } else {
        svgElement.removeAttribute('height');
      }

      if (originalAttributes.transform !== null) {
        svgElement.setAttribute('transform', originalAttributes.transform);
      } else {
        svgElement.removeAttribute('transform');
      }

      // Create the PDF and trigger download
      const pdfDoc = <PdfShapeRenderer svgString={svgString} />;
      const pdfBlob = await pdf(pdfDoc).toBlob();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.download = 'shapes.pdf';
      link.click();

      setIsLoading(false);
    }
  };

  return (
    <Button
      onClick={handleExportClick}
      isLoading={isLoading}
      colorScheme="gray"
      leftIcon={<SparkelIcon icon={faFile} color={'inherit'} />}
    >
      Export to PDF
    </Button>
  );
};

export default ExportButton;
