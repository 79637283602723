import { groupBy, isNil, join, map, sum, sumBy, uniq } from 'lodash';
import invariant from 'tiny-invariant';
import { evaluate, round } from 'mathjs';
import {
  ModelsMap,
  ModelsNameMap,
  ShapeFoldersMap,
  ShapesMap,
  SheetCalibrationsMap,
  SparkelPropertiesMap,
} from '../components/common/ForgeViewer';
import {
  CalculationType,
  ElementIdentifierDbidType,
  ForgeAttributePredicateType,
  ForgeAttributeQueryType,
  ForgeAttributeType,
  PredicateType,
  Quantity,
  QuantityEnum,
  QuantityOperation,
  ShapeDeepFragment,
  ShapeFolderDeepFragment,
  SheetShapeDeepFragment,
} from '../gql/graphql';
import { intersection } from '../utils/dbid-utils';
import { visibleFirstObjects } from '../services/viewer/ForgeContextMenu';
import { convertQuantityToSIUnits } from '../utils/unit-utils';
import { calculateGeometry } from './geometric-operations';
import { isGeometricAlgorithmType } from './geometry/algorithm-types';
import {
  OperationResult,
  SPARKEL_GEOMETRIC_PROPERTY_SET,
  isGeometricProperty,
} from './geometry/geometric-types';
import {
  Property,
  PropertyType,
  QuantityWithUnits,
  ResolvedQuantity,
} from './property-types';

const aggregate = (
  properties: Property[],
  operation: QuantityOperation,
  dbIds: ElementIdentifierDbidType[]
): QuantityWithUnits => {
  switch (operation) {
    case QuantityOperation.Sum: {
      invariant(
        properties.every((property) => property.units === properties[0].units),
        () =>
          `All properties should have equal units. Found ${uniq(
            map(properties, (properties) => properties.units)
          )}`
      );

      return convertQuantityToSIUnits({
        value: sumBy(properties, (property) => {
          if (typeof property.value === 'number') {
            return property.value;
          } else {
            const parsedNumberMatch = property.value.match(
              VALID_NUMBER_STRING_PATTERN
            );
            if (parsedNumberMatch === null) {
              return 0;
            } else {
              return Number.parseFloat(
                // This is okay given the regex
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                parsedNumberMatch.groups!.value.replaceAll(/,| /g, '')
              );
            }
          }
        }),
        units:
          properties[0]?.units
            ?.replaceAll('\u00B2', '^2')
            .replaceAll('\u00B3', '^3') || null,
      });
    }
    case QuantityOperation.Count:
      return {
        value: properties.length,
        units: null,
      };

    case QuantityOperation.Extract: {
      const propertiesByUrn = groupBy(
        properties,
        (property) => property.modelUrn
      );
      const propertiesByUrnAndDbId = Object.fromEntries(
        Object.keys(propertiesByUrn).map((urn) => [
          urn,
          groupBy(propertiesByUrn[urn], (property) => property.dbId),
        ])
      );

      return convertQuantityToSIUnits({
        // return the value of the property for each dbId, or NaN if no property exists
        value: dbIds.reduce((result, { modelUrn, dbIds }) => {
          const propertiesForModel = propertiesByUrnAndDbId[modelUrn] ?? {};
          dbIds.forEach((dbId) => {
            const propertiesForDbId = propertiesForModel[dbId] ?? [];
            if (propertiesForDbId.length === 0) {
              result.push(NaN);
            } else {
              const value = propertiesForDbId[0].value;
              if (typeof value === 'number') {
                result.push(value);
              } else {
                const tokens = value.split(';');
                result.push(join(tokens.sort(), ';'));
              }
            }
          });
          return result;
        }, [] as (number | string)[]),
        units:
          properties[0]?.units
            ?.replaceAll('\u00B2', '^2')
            .replaceAll('\u00B3', '^3') || null,
      });
    }
  }
};

export type SheetShapeWithSheetInfo = SheetShapeDeepFragment & {
  sheet: {
    id: string;
    name: string;
    filename: string;
  };
};

export type SheetShapeWithSheetInfoMap = Record<
  string,
  SheetShapeWithSheetInfo[]
>;

export interface PropertyContext {
  loadedModels: ModelsMap;
  loadedShapes: ShapesMap;
  loadedSheetShapes: SheetShapeWithSheetInfoMap;
  loadedSheetCalibrations: SheetCalibrationsMap;
  loadedShapeFolders: ShapeFoldersMap;
  loadedSparkelProperties: SparkelPropertiesMap;
  loadedActiveModelsNameMap: ModelsNameMap;
}

export const resolveQuantity = async (
  context: PropertyContext,
  modelDbIds: ElementIdentifierDbidType[],
  quantity: Omit<Quantity, '__typename'>,
  derivedFormulaScope?: Map<string, ResolvedQuantity['value']>
): Promise<ResolvedQuantity> => {
  switch (quantity.type) {
    case QuantityEnum.Static:
      if (!isNil(quantity.value)) {
        return {
          value: quantity.value,
          units: null,
        };
      } else if (!isNil(quantity.stringValue)) {
        return {
          value: quantity.stringValue,
          units: null,
        };
      } else {
        return {
          value: 0, // Todo: consider returning null instead
          units: null,
        };
      }

    case QuantityEnum.Dynamic:
      invariant(
        quantity.calculation,
        'Calculation must be set for dynamic quantity'
      );
      return resolveDynamicQuantity(context, modelDbIds, quantity.calculation);

    case QuantityEnum.Derived:
      invariant(quantity.derivedFormula, 'Formula is missing');
      try {
        const derivedValue: number | string = evaluate(
          quantity.derivedFormula,
          derivedFormulaScope
        );
        if (typeof derivedValue === 'number') {
          return {
            value: round(derivedValue, 2),
            units: null, // todo: consider carring units in the formula
          };
        } else {
          return {
            value: derivedValue,
            units: null,
          };
        }
      } catch (err) {
        return {
          value: NaN,
          units: null,
        };
      }
  }
};

// Inspired by https://regexr.com/38sbo and tweaked to include unit postfix
export const VALID_NUMBER_STRING_PATTERN =
  /^(?<value>-?\d+(,| \d{3})*(\.\d+)?)(?<unit> [\^\w]+)?$/;
export const resolveDynamicQuantity = async (
  context: PropertyContext,
  modelDbIds: ElementIdentifierDbidType[],
  calculation: CalculationType
): Promise<ResolvedQuantity> => {
  const { operation, attribute } = calculation;
  // COUNT special case: No need to select properties as the result is the length of the input dbIds
  if (operation === QuantityOperation.Count) {
    return {
      value: sum(map(modelDbIds, ({ dbIds }) => dbIds.length)),
      units: null,
    };
  }

  invariant(attribute, 'Attribute must be set when extracting values');
  const properties = await resolveProperties(context, attribute, modelDbIds);
  const result = aggregate(properties, operation, modelDbIds);
  return {
    ...result,
    dataPoints: properties,
  };
};

// Returns all dbids in models matching the given query
export const resolveElementIdentifiersDBID = async (
  context: PropertyContext,
  query: ForgeAttributeQueryType
): Promise<ElementIdentifierDbidType[]> => {
  let result: ElementIdentifierDbidType[] = [];
  //Always choose geometric properties last
  const geometricPredicates = query.predicates.filter(
    (predicate) =>
      predicate.attribute.category === SPARKEL_GEOMETRIC_PROPERTY_SET
  );

  const otherPredicates = query.predicates.filter(
    (predicate) =>
      predicate.attribute.category !== SPARKEL_GEOMETRIC_PROPERTY_SET
  );

  // Combine the sorted geometric predicates with the original order of other predicates
  const sortedPredicates = otherPredicates.concat(geometricPredicates);

  for (const predicate of sortedPredicates) {
    const properties = await resolveProperties(
      context,
      // For NOT_EXISTS and NOT_CONTAINS, we want properties that are missing the attribute
      predicate.predicate === PredicateType.NotExists ||
        predicate.predicate === PredicateType.NotContains
        ? undefined
        : predicate.attribute,
      // We only need to consider the remaining dbIds
      result.length === 0 ? undefined : result
    );
    const matchingDbIds = dbIdsMatchingPredicate(properties, predicate);
    // Initial pass, set properties to all dbIds matching predicate
    if (result.length === 0) {
      result = matchingDbIds;
    } else {
      // Narrow properties by dbIds also matching current predicate
      result = intersection(result, matchingDbIds);
    }
    // No dbIds match all predicates, we can stop
    if (result.length === 0) {
      break;
    }
  }
  return result;
};

enum AutodeskValueType {
  Boolean = 1,
  Number = 3,
  Text = 20,
}

function getPropertyType(autodeskType: number): PropertyType {
  switch (autodeskType) {
    case AutodeskValueType.Boolean:
      return PropertyType.Boolean;
    case AutodeskValueType.Number:
      return PropertyType.Number;
    case AutodeskValueType.Text:
      return PropertyType.Text;
    default:
      return PropertyType.Unknown;
  }
}

export function toPropertyArray(
  propertyResult: Autodesk.Viewing.PropertyResult,
  modelUrn: string
): Property[] {
  return propertyResult.properties.map(
    (autodeskProperty): Property =>
      ({
        attribute: {
          category: autodeskProperty.displayCategory,
          name: autodeskProperty.attributeName,
        },
        value: autodeskProperty.displayValue,
        type: getPropertyType(autodeskProperty.type),
        units: autodeskProperty.units,
        dbId: propertyResult.dbId,
        modelUrn,
      } as Property)
  );
}

// Note: this modifies the property in place
function convertModelName(
  property: Property,
  loadedActiveModelsNameMap: ModelsNameMap
): void {
  if (
    property.attribute.name === 'Source File' &&
    typeof property.value === 'string'
  ) {
    try {
      const value2 = loadedActiveModelsNameMap[property.value];
      if (value2) property.value = value2;
    } catch (err) {
      //Ignore and dont change name if not found in map
    }
  }
}

function dbIdsMatchingPredicate(
  properties: Property[],
  predicate: ForgeAttributePredicateType
): ElementIdentifierDbidType[] {
  const propertiesByModel = groupBy(
    properties,
    (property) => property.modelUrn
  );
  return Object.keys(propertiesByModel)
    .map((modelUrn) => {
      const propertiesForModel = propertiesByModel[modelUrn];
      const propertiesForDbId = groupBy(
        propertiesForModel,
        (property) => property.dbId
      );

      const matchingDbIds = Object.values(propertiesForDbId)
        .filter((properties) => propertiesPredicate(properties, predicate))
        // Grouped by dbId, so every element will have the same dbId
        .map((properties) => properties[0].dbId);
      return { modelUrn, dbIds: matchingDbIds } as ElementIdentifierDbidType;
    })
    .filter((modelDbId) => modelDbId.dbIds.length > 0);
}

// eslint-disable-next-line complexity
function propertiesPredicate(
  propertiesForDbId: Property[],
  predicate: ForgeAttributePredicateType
): boolean {
  // In most cases there's only a single attribute (e.g. "Name" EQUALS "foo")
  const predicateValue = predicate.attributeValues?.[0] ?? '';

  const isAttributeEqual = (property: Property) =>
    property.attribute.category === predicate.attribute.category &&
    property.attribute.name === predicate.attribute.name;

  const isPredicateValueEqual = (property: Property) => {
    if (typeof property.value === 'number') {
      return (
        Math.abs(property.value - Number.parseFloat(predicateValue)) < 0.01
      );
    }
    return property.value === predicateValue;
  };

  const somePropertyMatches = (predicate: (property: Property) => boolean) => {
    return propertiesForDbId.some(
      (property) => isAttributeEqual(property) && predicate(property)
    );
  };

  const doesNotContainPredicateValue = (property: Property) => {
    if (
      typeof property.value === 'string' ||
      typeof property.value === 'number'
    ) {
      return !property.value
        .toString()
        .toLowerCase()
        .includes(predicateValue.toLowerCase());
    }
    return true;
  };

  switch (predicate.predicate) {
    case PredicateType.Equals:
      return somePropertyMatches((property) => isPredicateValueEqual(property));
    case PredicateType.NotEquals:
      return somePropertyMatches(
        (property) => !isPredicateValueEqual(property)
      );
    case PredicateType.Contains:
      return somePropertyMatches((property) =>
        property.value
          .toString()
          .toLowerCase()
          .includes(predicateValue.toLowerCase())
      );
    case PredicateType.NotContains:
      // Check if there are no properties matching the attribute or if all properties that match do not contain the predicate value.
      return propertiesForDbId.every(
        (property) =>
          !isAttributeEqual(property) || doesNotContainPredicateValue(property)
      );
    case PredicateType.Exists:
      return propertiesForDbId.some((property) => isAttributeEqual(property));
    case PredicateType.NotExists:
      // Note the use of every. For NOT_EXISTS we have to check that there are no properties for the dbId with the given attribute
      return propertiesForDbId.every((property) => !isAttributeEqual(property));
    case PredicateType.GreaterThan:
      return somePropertyMatches(
        (property) =>
          (typeof property.value === 'string'
            ? Number.parseFloat(property.value)
            : property.value) > Number.parseFloat(predicateValue)
      );
    case PredicateType.LessThan:
      return somePropertyMatches(
        (property) =>
          (typeof property.value === 'string'
            ? Number.parseFloat(property.value)
            : property.value) < Number.parseFloat(predicateValue)
      );

    case PredicateType.AnyOf:
      return somePropertyMatches((property) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        predicate.attributeValues!.includes(String(property.value))
      );
  }
}

export const resolveProperties = async (
  context: PropertyContext,
  attribute?: ForgeAttributeType,
  dbIds?: ElementIdentifierDbidType[],
  maxTrianglesForComplexAlgorithm = 5000
): Promise<Property[]> => {
  // if (attribute?.category === SPARKEL_GEOMETRIC_PROPERTY_SET) {
  //   if (!dbIds) {
  //     throw new Error(
  //       'dbIds must be set when resolving geometric properties for now'
  //     );
  //   }
  //   return resolveGeometricProperties(context, attribute, dbIds);
  // }

  const modelProperties = await resolveModelProperties(
    context,
    dbIds,
    attribute
  );

  let geometricProperties: Property[] = [];
  if (dbIds)
    geometricProperties = await resolveGeometricProperties(
      context,
      dbIds,
      attribute,
      maxTrianglesForComplexAlgorithm
    );

  const shapeProperties = resolveShapeProperties(context, dbIds, attribute);

  const sparkelProperties: Property[] = resolveSparkelProperties(
    context,
    dbIds,
    attribute
  );

  return [
    ...modelProperties,
    ...shapeProperties,
    ...sparkelProperties,
    ...geometricProperties,
  ];
};

async function resolveModelProperties(
  context: PropertyContext,
  dbIds?: ElementIdentifierDbidType[],
  attribute?: ForgeAttributeType
) {
  let properties: Property[] = [];

  for (const [modelUrn, model] of Object.entries(context.loadedModels)) {
    let dbIdFilter: number[] = [];
    if (dbIds) {
      const dbIdsForModel = dbIds.find(
        (dbId) => dbId.modelUrn === modelUrn
      )?.dbIds;
      if (!dbIdsForModel || dbIdsForModel.length === 0) {
        continue;
      }
      dbIdFilter = dbIdsForModel;
    }

    const autodeskProperties = await new Promise<
      Autodesk.Viewing.PropertyResult[]
    >((resolve, reject) => {
      model.getBulkProperties2(
        dbIdFilter,
        {
          propFilter: attribute ? [attribute.name] : undefined,
          categoryFilter: attribute ? [attribute.category] : undefined,
        },
        resolve,
        () => void reject('Failed to get properties')
      );
    });
    const propertiesForModel = autodeskProperties.flatMap((propertyResult) =>
      toPropertyArray(propertyResult, modelUrn)
    );
    propertiesForModel.forEach(convertQuantityToSIUnits);
    propertiesForModel.forEach((prop) =>
      convertModelName(prop, context.loadedActiveModelsNameMap)
    );
    properties = properties.concat(propertiesForModel);
  }
  return properties;
}

function resolveSparkelProperties(
  context: PropertyContext,
  dbIds?: ElementIdentifierDbidType[],
  attribute?: ForgeAttributeType
) {
  const properties: Property[] = [];

  for (const [modelUrn, sparkelAttributes] of [
    ...Object.entries(context.loadedSparkelProperties),
  ]) {
    let dbIdFilter: number[] = [];
    if (dbIds) {
      const dbIdsForModel = dbIds.find(
        (dbId) => dbId.modelUrn === modelUrn
      )?.dbIds;
      if (!dbIdsForModel || dbIdsForModel.length === 0) {
        continue;
      }
      dbIdFilter = dbIdsForModel;
    }

    if (!attribute || attribute.category === sparkelPropertySet) {
      sparkelAttributes.forEach((sparkelProperty) => {
        if (
          (dbIdFilter.length === 0 ||
            dbIdFilter.includes(sparkelProperty.dbId)) &&
          (!attribute || attribute.name === sparkelProperty.propertySet)
        ) {
          properties.push({
            modelUrn: sparkelProperty.modelUrn,
            dbId: sparkelProperty.dbId,
            attribute: {
              category: sparkelPropertySet,
              name: sparkelProperty.propertySet,
            },
            value: sparkelProperty.propertyValue,
            type: PropertyType.Text,
            units: null,
          });
        }
      });
    }
  }

  return properties;
}

function resolveShapeProperties(
  context: PropertyContext,
  dbIds?: ElementIdentifierDbidType[],
  attribute?: ForgeAttributeType
) {
  const properties: Property[] = [];

  for (const [modelUrn, shapes] of [
    ...Object.entries(context.loadedShapes),
    ...Object.entries(context.loadedSheetShapes),
  ]) {
    let dbIdFilter: number[] = [];
    if (dbIds) {
      const dbIdsForModel = dbIds.find(
        (dbId) => dbId.modelUrn === modelUrn
      )?.dbIds;
      if (!dbIdsForModel || dbIdsForModel.length === 0) {
        continue;
      }
      dbIdFilter = dbIdsForModel;
    }

    const propertiesForShape: Property[] = (
      shapes as (SheetShapeWithSheetInfo | ShapeDeepFragment)[]
    )
      .filter(
        (shape) => dbIdFilter.length === 0 || dbIdFilter.includes(shape.dbId)
      )
      .flatMap((shape) =>
        allPropertiesForShape(
          shape,
          modelUrn,
          Object.values(context.loadedShapeFolders).flatMap(
            (folders) => folders
          )
        )
      )
      .filter(
        (property) =>
          !attribute ||
          (attribute.category === property.attribute.category &&
            attribute.name === property.attribute.name)
      );
    properties.push(...propertiesForShape);
  }
  return properties;
}

function allPropertiesForShape(
  shape: ShapeDeepFragment | SheetShapeWithSheetInfo,
  modelUrn: string,
  shapeFolders: ShapeFolderDeepFragment[]
): Property[] {
  const properties: Property[] = [
    {
      modelUrn,
      dbId: shape.dbId,
      attribute: { category: shapesPropertySet, name: ShapesProperties.NAME },
      value: shape.name,
      type: PropertyType.Text,
      units: null,
    },
    {
      modelUrn,
      dbId: shape.dbId,
      attribute: { category: shapesPropertySet, name: ShapesProperties.TYPE },
      value: getShapeTypeProperty(shape),
      type: PropertyType.Text,
      units: null,
    },
  ];
  const shapeFolder = getShapeFolderName(shape, shapeFolders);
  if (shapeFolder) {
    properties.push({
      modelUrn,
      dbId: shape.dbId,
      attribute: { category: shapesPropertySet, name: ShapesProperties.FOLDER },
      value: shapeFolder,
      type: PropertyType.Text,
      units: null,
    });
  }
  if (shape.__typename === 'SheetShape') {
    properties.push({
      modelUrn,
      dbId: shape.dbId,
      attribute: {
        category: shapesPropertySet,
        name: ShapesProperties.SOURCE_FILE,
      },
      value: shape.sheet.filename,
      type: PropertyType.Text,
      units: null,
    });
    properties.push({
      modelUrn,
      dbId: shape.dbId,
      attribute: {
        category: shapesPropertySet,
        name: ShapesProperties.PAGE_NUMBER,
      },
      value: shape.sheetPageNumber,
      type: PropertyType.Number,
      units: null,
    });
  }
  return properties;
}

export const shapesPropertySet = 'Shape';

export enum ShapesProperties {
  NAME = 'Name',
  TYPE = 'Type',
  FOLDER = 'Folder',
  SOURCE_FILE = 'Source File',
  PAGE_NUMBER = 'Page Number',
}

export enum ShapeType {
  POLYGON = 'Polygon',
  LINE = 'Line',
  POINT = 'Point',
}

export const sparkelPropertySet = 'Sparkel Attributes';

const resolveGeometricProperties = async (
  context: PropertyContext,
  dbIds: ElementIdentifierDbidType[],
  attribute?: ForgeAttributeType,
  maxTrianglesForComplexAlgorithm?: number
): Promise<Property[]> => {
  if (!attribute) {
    // Selecting all geometric properties is not supported yet
    return [];
  }
  if (!dbIds) {
    //  Selecting geometric properties for all dbIds is not supported yet
    return [];
  }
  if (attribute.category !== (SPARKEL_GEOMETRIC_PROPERTY_SET || 'Quantities')) {
    return [];
  }

  if (
    isGeometricProperty(attribute.name) ||
    isGeometricAlgorithmType(attribute.name)
  ) {
    const operationResult = await calculateGeometry(
      attribute.name,
      dbIds,
      context,
      maxTrianglesForComplexAlgorithm
    );
    return operationResult
      .map((result) => mapGeometricResultToProperty(result, attribute.name))
      .filter((property) => !isNil(property)) as Property[];
  } else {
    throw new Error('Expected geometric propertytype or algorithmtype');
  }
};

const mapGeometricResultToProperty = (
  [result, context]: OperationResult,
  attributeName: string
): Property | undefined => {
  if (result === undefined) {
    return undefined;
  }
  return {
    ...result,
    type: PropertyType.Number,
    modelUrn: context.modelUrn,
    dbId: context.dbId,
    attribute: {
      category: SPARKEL_GEOMETRIC_PROPERTY_SET,
      name: attributeName,
    },
  };
};

// Use the same property value for both 3D and sheet shapes, e.g. both SheetShapePolygon and ShapePolygon will have the property value "Polygon"
function getShapeTypeProperty(
  shape: ShapeDeepFragment | SheetShapeDeepFragment
) {
  if (shape.__typename === 'SheetShape') {
    if (shape.sheetShapePolygon) {
      return ShapeType.POLYGON;
    } else if (shape.sheetShapeLine) {
      return ShapeType.LINE;
    } else {
      return ShapeType.POINT;
    }
  } else {
    if (shape.polygon) {
      return ShapeType.POLYGON;
    } else if (shape.line) {
      return ShapeType.LINE;
    } else {
      return ShapeType.POINT;
    }
  }
}

function getShapeFolderName(
  shape: ShapeDeepFragment | SheetShapeDeepFragment,
  folders: ShapeFolderDeepFragment[]
) {
  return folders.find((folder) => folder.id === shape.folder)?.name;
}

export async function getPropertyByDbId(
  model: Autodesk.Viewing.Model,
  dbId: number,
  attributeName: string
): Promise<string | null> {
  async function findProperty(dbId: number): Promise<string | null> {
    return new Promise((resolve, reject) => {
      model.getProperties(
        dbId,
        async (props) => {
          const categoryProp = props.properties.find(
            (prop) => prop.attributeName === attributeName
          );
          if (categoryProp) {
            resolve(categoryProp.displayValue.toString());
          } else {
            // Look for parent property
            const parentProp = props.properties.find(
              (prop) => prop.attributeName === 'parent'
            );
            if (parentProp && parentProp.displayValue) {
              const parentId = parseInt(parentProp.displayValue as string);
              if (!isNaN(parentId) && parentId !== dbId) {
                // Check to avoid infinite loop
                const parentCategory = await findProperty(parentId);
                resolve(parentCategory);
              } else {
                resolve(null); // No valid parent ID found, or same as dbId
              }
            } else {
              resolve(null); // No parent property found
            }
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  return await findProperty(dbId);
}

export async function getParentDbId(
  model: Autodesk.Viewing.Model,
  dbId: number
): Promise<number> {
  return new Promise((resolve) => {
    model.getProperties(dbId, async (props) => {
      const parentProp = props.properties.find(
        (prop) => prop.attributeName === 'parent'
      );
      if (parentProp && parentProp.displayValue) {
        const parentId = parseInt(parentProp.displayValue as string);
        if (!isNaN(parentId)) {
          resolve(parentId);
        } else {
          resolve(0); // No valid parent ID found, or same as dbId
        }
      } else {
        resolve(0); // No parent property found
      }
    });
  });
}

export async function getAllChildrenDbIds(
  model: Autodesk.Viewing.Model,
  dbId: number
): Promise<number[]> {
  const children: number[] = [dbId];

  return new Promise((resolve) => {
    model.getData().instanceTree.enumNodeChildren(dbId, (childId: number) => {
      children.push(childId);
    });
    resolve(children);
  });
}

async function getFirstObjectDbId(
  model: Autodesk.Viewing.Model,
  dbId: number
): Promise<number> {
  const visibleFirstObjectDbIds = visibleFirstObjects(model);

  if (visibleFirstObjectDbIds.has(dbId)) {
    return dbId;
  }

  const parentDbId = await getParentDbId(model, dbId);
  return getFirstObjectDbId(model, parentDbId);
}

export async function getAllRelatedDbIds(
  model: Autodesk.Viewing.Model,
  dbId: number
): Promise<number[]> {
  const firstObject = await getFirstObjectDbId(model, dbId);

  return getAllChildrenDbIds(model, firstObject);
}
