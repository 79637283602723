import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useQuery } from 'urql';
import {
  GetActiveModelsForProjectDocument,
  ModelsDeepFragment,
} from '../gql/graphql';

export function useActiveModels(projectId: string) {
  const [{ data: bimModelsData, fetching, error }] = useQuery({
    query: GetActiveModelsForProjectDocument,
    variables: { projectId },
  });
  const [currentValue, setCurrentValue] = useState<ModelsDeepFragment[] | null>(
    bimModelsData?.project?.activeModels ?? null
  );

  useEffect(() => {
    // Because models reference is updated when polling,
    // use deep equality to prevent viewer hooks from going amok
    if (!isEqual(bimModelsData?.project?.activeModels, currentValue)) {
      setCurrentValue(bimModelsData?.project?.activeModels ?? null);
    }
  }, [bimModelsData?.project?.activeModels, currentValue]);

  return { activeModels: currentValue, fetching, error };
}
