import {
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useColorModeValue,
  useDisclosure,
  useToken,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { faAngleDown, faGear } from '@fortawesome/free-solid-svg-icons';
import { find } from 'lodash';
import invariant from 'tiny-invariant';
import { ReactElement } from 'react';
import SparkelIcon from '../common/icon/SparkelIcon';
import ProjectSettingsModal from '../company/projectsPage/ProjectSettingsModal';

type ModuleNavigationProps = {
  items: {
    path: string;
    label: string;
    icon: IconProp;
    default?: boolean;
    matchingPaths?: string[];
    rightIcon?: ReactElement;
  }[];
  projectId: string;
};

export default function ModuleNavigation({
  items,
  projectId,
}: ModuleNavigationProps): React.ReactElement {
  const [brand500] = useToken('colors', ['brand.500']);
  const { pathname } = useLocation();

  const {
    isOpen: settingsOpen,
    onOpen: openSettings,
    onClose: closeSettings,
  } = useDisclosure();
  let activeItem = find(
    items,
    (item) => !!matchPath(item.path + '/*', pathname)
  );
  if (!activeItem) {
    activeItem = find(items, { default: true });
    invariant(activeItem, 'No matching route found');
  }

  const activeFontColor = useColorModeValue('blue.700', 'blue.200');
  const activeBackgroundColor = useColorModeValue('blue.50', 'blue.800');

  return (
    <>
      <Menu isLazy>
        <MenuButton
          as={Button}
          colorScheme="gray"
          size={'sm'}
          leftIcon={<SparkelIcon icon={activeItem.icon} size="sm" />}
          rightIcon={<SparkelIcon icon={faAngleDown} size="sm" />}
        >
          {activeItem.label}
          {activeItem.rightIcon}
        </MenuButton>
        <Portal>
          <MenuList padding={1} zIndex={1500}>
            {items.map((item) => {
              const activeStyles =
                item.path === pathname
                  ? {
                      color: activeFontColor,
                      backgroundColor: activeBackgroundColor,
                      fontWeight: 'bold',
                    }
                  : undefined;
              return (
                <MenuItem
                  {...activeStyles}
                  borderRadius={'lg'}
                  as={Link}
                  key={item.label}
                  to={item.path}
                  icon={
                    <SparkelIcon
                      icon={item.icon}
                      fixedWidth
                      color={'inherit'}
                    />
                  }
                >
                  {item.label}
                  {item.rightIcon}
                </MenuItem>
              );
            })}
            <Divider />
            <MenuItem
              onClick={openSettings}
              icon={<SparkelIcon icon={faGear} fixedWidth />}
            >
              Project settings
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>

      <ProjectSettingsModal
        projectId={projectId}
        modalIsOpen={settingsOpen}
        closeModal={closeSettings}
      ></ProjectSettingsModal>
    </>
  );
}
