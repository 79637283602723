import { chakra } from '@chakra-ui/react';
import { useEffect, useMemo, useRef } from 'react';
import {
  getPolygon2PathData,
  transformMultipolygonCoordinates,
} from '../../../components/orderContractor/shapes/util';
import {
  LinearRing2,
  MultiPolygon2,
  MultiPolygon2Result,
  Point2,
} from '../../../domain/geometry/geometric-types';
import { useUserTenant } from '../../auth-info';
import { MultiPolygon2AlgorithmType } from 'src/domain/geometry/algorithm-types';
import { useExportToPdf } from 'src/components/common/PDFExportContext';

type Polygon2ResultVisualizationProps = {
  result: MultiPolygon2Result;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
  enclosingPolygon?: MultiPolygon2;
  algorithmType?: MultiPolygon2AlgorithmType;
};

export const Polygon2ResultVisualization = ({
  result,
  getPointInDomCoordinateSystem,
  enclosingPolygon,
  algorithmType,
}: Polygon2ResultVisualizationProps) => {
  const pathData = useMemo(() => {
    // The enclosing polygon will be used to invert the result polygon
    if (!enclosingPolygon) {
      return transformMultipolygonCoordinates(
        result.multiPolygon,
        getPointInDomCoordinateSystem
      ).polygons.map((polygon) => getPolygon2PathData(polygon));
    }

    // The enclosing polygon is assumed to be a single polygon for now

    const exterior = enclosingPolygon.polygons[0].exterior;
    const enclosingInteriors = enclosingPolygon.polygons[0].interiors;

    const interiors: LinearRing2[] = result.multiPolygon.polygons.flatMap(
      (polygon) => [polygon.exterior, ...polygon.interiors]
    );

    const newPolygon: MultiPolygon2 = {
      polygons: [
        {
          exterior: exterior,
          interiors: [...interiors, ...enclosingInteriors],
        },
      ],
    };

    return transformMultipolygonCoordinates(
      newPolygon,
      getPointInDomCoordinateSystem
    ).polygons.map((polygon) => getPolygon2PathData(polygon));
  }, [enclosingPolygon, getPointInDomCoordinateSystem, result.multiPolygon]);

  const tenant = useUserTenant();

  const { addSvgRef, clearSvgRefs } = useExportToPdf();
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (svgRef.current) {
      addSvgRef(svgRef);
    }

    return () => {
      clearSvgRefs();
    };
  }, [addSvgRef, clearSvgRefs]);

  let strokeColor: string | undefined = undefined;
  let fillColor = 'teal.400';
  let fillOpacity = 0.7;

  if (tenant.tenant?.group === 'Protan' && algorithmType) {
    switch (algorithmType) {
      case MultiPolygon2AlgorithmType.POLYGON2_FIRE_SECTIONING:
        fillColor = 'yellow.400';
        strokeColor = 'yellow.600';
        fillOpacity = 0.5;
        break;
      case MultiPolygon2AlgorithmType.POLYGON2_CORNER_AREA:
        fillColor = 'blue.50';
        strokeColor = 'blue.300';
        break;
      case MultiPolygon2AlgorithmType.POLYGON2_RAND_AREA:
        fillColor = 'blue.200';
        strokeColor = 'blue.600';
        break;
    }
  }

  return (
    <chakra.svg
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      opacity={fillOpacity}
      fillRule={'evenodd'}
      strokeWidth={2}
      stroke={strokeColor}
      fill={fillColor}
      ref={svgRef}
    >
      {pathData.map((data, index) => (
        <chakra.path key={index} d={data} />
      ))}
    </chakra.svg>
  );
};
