import { chakra } from '@chakra-ui/react';
import { useMemo } from 'react';
import {
  Point2,
  MultiPolyline2Result,
  MultiPolyline2,
} from '../../../domain/geometry/geometric-types';
import { useUserTenant } from '../../auth-info';

type Polyline2ResultVisualizationProps = {
  result: MultiPolyline2Result;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
};
export const Polyline2ResultVisualization = ({
  result,
  getPointInDomCoordinateSystem,
}: Polyline2ResultVisualizationProps) => {
  const resultInDom = useMemo<MultiPolyline2>(() => {
    return {
      lines: result.multiPolyline.lines.map((polyline) =>
        polyline.map((point) => getPointInDomCoordinateSystem(point))
      ),
    };
  }, [getPointInDomCoordinateSystem, result.multiPolyline]);

  const { tenant } = useUserTenant();

  return (
    <chakra.svg
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      opacity={tenant?.group === 'Protan' ? 1 : 0.7}
    >
      {resultInDom.lines.map((polyline, index) => (
        <chakra.polyline
          key={index}
          points={polyline.map(([x, y]) => `${x},${y}`).join(' ')}
          strokeWidth={tenant?.group === 'Protan' ? 2 : 4}
          stroke={tenant?.group === 'Protan' ? 'green.300' : 'teal.400'}
          fill="none"
        />
      ))}
    </chakra.svg>
  );
};
