import { without } from 'lodash';
import * as math from 'mathjs';
import {
  LineSegment,
  LineSegment2,
} from '../../../domain/geometry/geometric-types';

// Map from edge index to list of edges that intersect it
export type EdgeIntersections<Id = string> = Map<Id, Id[]>;

export type EdgeWithId<
  Id = string,
  EdgeType extends LineSegment | LineSegment2 = LineSegment2
> = {
  id: Id;
  segment: EdgeType;
};

// Deep copy of the intersections map
export function copy<Id>(
  intersections: EdgeIntersections<Id>
): EdgeIntersections<Id> {
  const copy = new Map<Id, Id[]>();
  for (const [key, value] of intersections.entries()) {
    copy.set(key, [...value]);
  }
  return copy;
}

// Updates the intersections map with the new edge
export function addEdge<Id>(
  intersections: EdgeIntersections<Id>,
  existingEdges: EdgeWithId<Id>[],
  newEdge: EdgeWithId<Id>
): void {
  const { id, segment } = newEdge;
  const intersectionsForEdge: Id[] = [];

  for (const { id, segment: existingSegment } of existingEdges) {
    if (id === newEdge.id) {
      continue;
    }
    if (intersects(segment, existingSegment)) {
      intersectionsForEdge.push(id);
      intersections.set(id, [...(intersections.get(id) ?? []), newEdge.id]);
    }
  }
  intersections.set(id, intersectionsForEdge);
}

// Updates the intersections map by removing the edge
export function removeEdge<Id>(
  intersections: EdgeIntersections<Id>,
  edgeToRemove: Id
) {
  const edgesToUnlink = intersections.get(edgeToRemove) ?? [];
  for (const edge of edgesToUnlink) {
    const edgesLinkedToEdge = intersections.get(edge) ?? [];
    intersections.set(edge, without(edgesLinkedToEdge, edgeToRemove));
  }
  intersections.delete(edgeToRemove);
}

export function updateEdge<Id>(
  intersections: EdgeIntersections<Id>,
  existingEdges: EdgeWithId<Id>[],
  edgeToUpdate: EdgeWithId<Id>
) {
  removeEdge(intersections, edgeToUpdate.id);
  addEdge(intersections, existingEdges, edgeToUpdate);
}

// eslint-disable-next-line complexity
export function intersects(
  segment1: LineSegment2,
  segment2: LineSegment2
): boolean {
  const [p0, p1] = segment1;
  const [p2, p3] = segment2;

  // Check for equal or opposite edges
  if (
    p0[0] === p2[0] &&
    p0[1] === p2[1] &&
    p1[0] === p3[0] &&
    p1[1] === p3[1]
  ) {
    return true;
  }
  if (
    p0[0] === p3[0] &&
    p0[1] === p3[1] &&
    p1[0] === p2[0] &&
    p1[1] === p2[1]
  ) {
    return true;
  }

  // Check for single shared point
  if (
    (p0[0] === p2[0] && p0[1] === p2[1]) ||
    (p0[0] === p3[0] && p0[1] === p3[1]) ||
    (p1[0] === p2[0] && p1[1] === p2[1]) ||
    (p1[0] === p3[0] && p1[1] === p3[1])
  ) {
    return false;
  }

  const s1_x = p1[0] - p0[0];
  const s1_y = p1[1] - p0[1];
  const s2_x = p3[0] - p2[0];
  const s2_y = p3[1] - p2[1];

  const s =
    (-s1_y * (p0[0] - p2[0]) + s1_x * (p0[1] - p2[1])) /
    (-s2_x * s1_y + s1_x * s2_y);
  const t =
    (s2_x * (p0[1] - p2[1]) - s2_y * (p0[0] - p2[0])) /
    (-s2_x * s1_y + s1_x * s2_y);

  if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
    // Collision detected
    return true;
  }

  return false;
}

const zeroLengthLineEpsilon = 1e-10;

export function getInvalidEdges<Id>(
  edges: EdgeWithId<Id, LineSegment | LineSegment2>[],
  intersections: EdgeIntersections<Id>
): Id[] {
  return edges
    .filter((edge) => !isValidEdge(edge.segment, intersections, edge.id))
    .map((edge) => edge.id);
}

export function isValidEdge<Id>(
  edge: LineSegment | LineSegment2,
  intersections: EdgeIntersections<Id>,
  edgeIndex: Id
): boolean {
  const [p0, p1] = edge;
  const intersectionsForEdge = intersections.get(edgeIndex) ?? [];
  const isZeroLength = math.smaller(
    math.distance(p0, p1),
    zeroLengthLineEpsilon
  );
  const hasIntersections = intersectionsForEdge.length > 0;
  return !isZeroLength && !hasIntersections;
}
