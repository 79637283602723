import * as math from 'mathjs';

import Coordinate from 'jsts/org/locationtech/jts/geom/Coordinate';
import GeometryFactory from 'jsts/org/locationtech/jts/geom/GeometryFactory';
import JstsLinearRing from 'jsts/org/locationtech/jts/geom/LinearRing';
import JstsPolygon from 'jsts/org/locationtech/jts/geom/Polygon';
import JstsMultiPolygon from 'jsts/org/locationtech/jts/geom/MultiPolygon';
import BufferOp from 'jsts/org/locationtech/jts/operation/buffer/BufferOp';

import {
  LinearRing2,
  MultiPolygon2,
  Point2,
  Polygon2,
  Triangle,
} from '../../geometric-types';

export const toJstsCoordinate = (point: Point2) =>
  new Coordinate(point[0], point[1]);

export const toJstsLinearRing = (ring: LinearRing2): JstsLinearRing => {
  const geomFactory = new GeometryFactory();
  const coordinates = ring.map(toJstsCoordinate);
  return geomFactory.createLinearRing(coordinates);
};

export const toJstsPolygon = (polygon: Polygon2): JstsPolygon => {
  const geomFactory = new GeometryFactory();
  const exterior = toJstsLinearRing(polygon.exterior);

  const interiors = polygon.interiors.map((ring) => toJstsLinearRing(ring));
  return geomFactory.createPolygon(exterior, interiors);
};

export const toJstsMultiPolygon = (
  multiPolygon: MultiPolygon2
): JstsMultiPolygon => {
  const geomFactory = new GeometryFactory();
  const jstsPolygons = multiPolygon.polygons.map(toJstsPolygon);
  return geomFactory.createMultiPolygon(jstsPolygons);
};

export const toPoint2 = (coordinate: Coordinate): Point2 => [
  coordinate.x,
  coordinate.y,
];
export const toLinearRing2 = (ring: Coordinate[]): LinearRing2 =>
  ring.map(toPoint2);

export const toPolygon2 = (polygon: JstsPolygon): Polygon2 => {
  const exterior = toLinearRing2(polygon.getExteriorRing().getCoordinates());
  const interiors = [];
  for (let i = 0; i < polygon.getNumInteriorRing(); i++) {
    interiors.push(toLinearRing2(polygon.getInteriorRingN(i).getCoordinates()));
  }

  return {
    exterior,
    interiors,
  };
};

export const toMultiPolygon2 = (
  multiPolygon: JstsMultiPolygon
): MultiPolygon2 => {
  const polygons = [];
  for (let i = 0; i < multiPolygon.getNumGeometries(); i++) {
    polygons.push(toPolygon2(multiPolygon.getGeometryN(i)));
  }
  return {
    polygons,
  };
};

export function union(multiPolygons: MultiPolygon2[]): MultiPolygon2 {
  const geomFactory = new GeometryFactory();
  const jstsPolygons = multiPolygons.map(toJstsMultiPolygon);
  const collection = geomFactory.createGeometryCollection(jstsPolygons);

  // Use BufferOp.union instead of union because it seems more stable - observed sporadic errors with union
  const union = BufferOp.bufferOp(collection, 0, 0);
  return toMultiPolygon2(union);
}

export function calculateArea(triangle: Triangle): number {
  const [p1, p2, p3] = triangle;

  const side1 = math.subtract(p3, p2);
  const side2 = math.subtract(p1, p2);

  return (math.norm(math.cross(side1, side2)) as number) / 2;
}

// Signed area of a linear ring
export function ringArea(ring: LinearRing2) {
  let area = 0;
  for (let i = 0; i < ring.length - 1; i++) {
    const currentPoint = ring[i];
    const nextPoint = ring[i + 1];
    area += currentPoint[0] * nextPoint[1] - nextPoint[0] * currentPoint[1];
  }
  return area / 2;
}

export function isCCW(ring: LinearRing2) {
  return ringArea(ring) > 0;
}

export function isPointWithinRing(ring: LinearRing2, point: Point2) {
  let isWithin = false;
  for (let i = 0, j = ring.length - 1; i < ring.length; j = i++) {
    const xi = ring[i][0];
    const yi = ring[i][1];
    const xj = ring[j][0];
    const yj = ring[j][1];
    const intersect =
      yi > point[1] !== yj > point[1] &&
      point[0] < ((xj - xi) * (point[1] - yi)) / (yj - yi) + xi;
    if (intersect) isWithin = !isWithin;
  }

  return isWithin;
}
