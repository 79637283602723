import {
  Avatar,
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  HStack,
  Hide,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Tooltip,
  useColorMode,
  useToken,
} from '@chakra-ui/react';
import {
  faLayerGroup,
  faList,
  faListCheck,
} from '@fortawesome/free-solid-svg-icons';
import React, {
  ReactElement,
  createContext,
  forwardRef,
  useContext,
  useRef,
} from 'react';
import {
  Link,
  NavLink as RouterNavLink,
  useMatch,
  useMatches,
  type NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';
import invariant from 'tiny-invariant';

import { useFlag } from '@unleash/proxy-client-react';
import { CommentsContext } from '../../App';
import logoFull from '../../assets/img/logo-full.svg';
import logo from '../../assets/img/logo-square-transparent.svg';
import protanLogo from '../../assets/img/protan-logo.png';
import { UserType, useUserTenant } from '../../services/auth-info';
import { useBreadcrumb } from '../common/BreadcrumbProvider';
import ModuleNavigation from '../navigation/DropdownNavigation';
import ExportButton from '../orderContractor/pdf-shapes/ExportToPDF';
import { useExportToPdf } from '../common/PDFExportContext';
import ColorModeSwitcher from './ColorModeSwitcher';
import { ProtanTableExporter } from './ProtanTableExporter';

type NavLinkProps = Omit<RouterNavLinkProps, 'ref'> & {
  disabled?: boolean;
};

export type CrumbContext = {
  projectId?: string;
  projectName?: string;
  orderId?: string;
  orderName?: string;
};

type Crumb = {
  (context: CrumbContext): {
    label: string;
    value: string;
    to: string;
  };
};

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, forwardededRef): React.ReactElement => {
    const [gray600, gray200] = useToken('colors', ['gray.600', 'gray.200']);
    const { colorMode } = useColorMode();
    const [bold] = useToken('fontWeights', ['bold']);

    if (props.disabled) {
      let child: React.ReactNode | null = null;
      if (typeof props.children === 'function') {
        // This is not 100% correct, but given our use of `end` below, it should be fine
        child = props.children({
          isActive: false,
          isPending: false,
        });
      } else {
        child = props.children;
      }
      return <Text fontSize="sm">{child}</Text>;
    } else {
      return (
        <RouterNavLink
          end
          style={({ isActive }) => ({
            fontWeight: isActive ? bold : undefined,
            color: isActive
              ? colorMode === 'light'
                ? gray600
                : gray200
              : undefined,
          })}
          {...props}
          ref={forwardededRef}
        />
      );
    }
  }
);

NavLink.displayName = 'NavLink';

type BreadcrumbItem = {
  label: string;
  value: string;
  to: string;
  disabled?: boolean;
};

type BreadcrumbContext = {
  breadcrumbItems: Array<BreadcrumbItem | null>;
  setBreadcrumbItems: (items: Array<BreadcrumbItem | null>) => void;
};

const BreadcrumbContext = createContext<BreadcrumbContext | null>(null);

export const HEADER_HEIGHT = 68;

// eslint-disable-next-line complexity
export default function Header(): React.ReactElement | null {
  const ref = useRef(null);
  const commentsContext = useContext(CommentsContext);
  commentsContext.setHeaderRef(ref);

  const enableIssues = useFlag('enable-issues');

  const user = useUserTenant();

  const { svgRef } = useExportToPdf();

  invariant(user.userDetails, 'User details should be defined at this point');

  const isContractorUser = user.userDetails.userType === UserType.Contractor;
  const readOnly = !isContractorUser;

  const { projectId, projectName, orderId, orderName } = useBreadcrumb();
  const projectPageRouteMatch = useMatch('/project/:projectId/*');
  const matches = useMatches();

  const breadCrumbs = matches.filter(
    (match) =>
      (
        match.handle as
          | {
              crumb: Crumb;
            }
          | undefined
      )?.crumb
  );

  const breadcrumbContext: CrumbContext = {
    projectId,
    projectName,
    orderId,
    orderName,
  };

  const breadCrumbItems = breadCrumbs.map((breadCrumb) => {
    const item = (
      breadCrumb.handle as {
        crumb: Crumb;
      }
    ).crumb(breadcrumbContext);
    if (!item.value) {
      return null;
    }
    return item;
  });

  if (
    breadCrumbItems.length > 0 &&
    breadCrumbItems.some((item) => item === null)
  ) {
    return null;
  }

  const handleSignOut = () => {
    user.signOut();
  };

  const moduleItems = [
    {
      path: `/project/${projectId}/resources`,
      label: 'Overview',
      default: true,
      icon: faList,
      rightIcon: undefined as ReactElement | undefined,
    },
    {
      path: `/project/${projectId}/takeoff`,
      label: 'Tables',
      default: true,
      icon: faLayerGroup,
      rightIcon: undefined as ReactElement | undefined,
    },
  ];

  if (enableIssues) {
    moduleItems.push({
      path: `/project/${projectId}/issues`,
      label: 'Issues',
      default: true,
      icon: faListCheck,
      rightIcon: (
        <Badge colorScheme="purple" marginLeft={1} size={'xs'}>
          beta
        </Badge>
      ),
    });
  }

  return (
    <HStack
      height={HEADER_HEIGHT}
      px={2}
      py={2}
      zIndex={4}
      borderBottom={'1px'}
      backgroundColor="white"
      borderColor="gray.200"
      _dark={{
        borderColor: 'gray.700',
        backgroundColor: 'gray.800',
      }}
    >
      <Box px={5}>
        {!readOnly ? (
          <Link to="/">
            <Image
              src={user.tenant?.group === 'Protan' ? protanLogo : logo}
              height={12}
              mt={'3px'}
              filter="invert(0)"
              _dark={{
                filter: 'invert(1)',
              }}
            />
          </Link>
        ) : (
          <Image
            src={logoFull}
            height={6}
            mt={'3px'}
            filter="invert(0)"
            _dark={{
              filter: 'invert(1)',
            }}
          />
        )}
      </Box>
      <Breadcrumb separator="/" color="gray.400">
        {user.tenant?.group !== 'Protan' ? (
          <BreadcrumbItem alignItems="flex-end">
            <Flex direction={['column']} gap={0} color="gray.400">
              <Hide below="md">
                <Text fontSize={'xs'}>Company</Text>
              </Hide>
              <BreadcrumbLink
                to={'/projects'}
                fontSize="md"
                as={NavLink}
                disabled={readOnly}
              >
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                {user!.tenant!.name}
              </BreadcrumbLink>
            </Flex>
          </BreadcrumbItem>
        ) : null}

        {(
          breadCrumbItems as Array<{
            label: string;
            value: string;
            to: string;
          }>
        ).map((item, index, items) => {
          return (
            <BreadcrumbItem key={item.to} alignItems="flex-end">
              <Flex direction={['column']} gap={0} color="gray.400">
                <Hide below="md">
                  <Text fontSize={'xs'}>{item.label}</Text>
                </Hide>
                <BreadcrumbLink
                  to={item.to}
                  as={NavLink}
                  fontSize="md"
                  disabled={readOnly && index < items.length - 1}
                >
                  {item.value}
                </BreadcrumbLink>
              </Flex>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
      <Spacer />
      {
        //Box with ref for comments button
        user.tenant?.group !== 'Protan' && <Box ref={ref} />
      }
      {svgRef && user.tenant?.group === 'Protan' && (
        <ExportButton svgRef={svgRef} />
      )}

      {isContractorUser &&
      user.tenant?.group !== 'Protan' &&
      projectPageRouteMatch !== null &&
      projectPageRouteMatch.params.projectId ? (
        <ModuleNavigation
          items={moduleItems}
          projectId={projectPageRouteMatch.params.projectId}
        />
      ) : null}
      {user.tenant?.group === 'Protan' && orderId ? (
        <ProtanTableExporter orderId={orderId} />
      ) : (
        <ColorModeSwitcher />
      )}
      <Menu isLazy>
        <Tooltip label="Account">
          <MenuButton>
            <Avatar size="sm" />
          </MenuButton>
        </Tooltip>
        <MenuList>
          <MenuGroup
            textAlign="center"
            fontSize="md"
            title={user.userDetails.displayName ?? ''}
          >
            <MenuItem
              isDisabled
              as={Box}
              justifyContent="center"
              textColor="gray.600"
              _dark={{
                textColor: 'gray.50',
              }}
            >
              {user.username}
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          {isContractorUser ? (
            <MenuItem as={Link} to="/user-settings">
              User settings
            </MenuItem>
          ) : null}
          <MenuItem onClick={handleSignOut} textColor="red.500">
            Sign out
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
}
