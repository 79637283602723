import { chakra } from '@chakra-ui/react';
import * as math from 'mathjs';
import {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { isHotkeyPressed, useHotkeys } from 'react-hotkeys-hook';
import invariant from 'tiny-invariant';
import { useFlag } from '@unleash/proxy-client-react';
import {
  calculatePlaneEquation,
  projectPointOnPlane,
  to3dMultiPolygon,
  to3dPoints,
  toPlanarPoint,
} from '../../../domain/geometry/algorithms/util/plane';
import {
  LineSegment2,
  LinearRing,
  MultiPolygon,
  Plane,
  Point,
} from '../../../domain/geometry/geometric-types';
import {
  addEdge,
  copy,
  getInvalidEdges,
  intersects,
} from '../../../services/viewer/shape-drawing/intersections';
import { getShape } from '../../../services/viewer/shape-drawing/one-click-shapes';
import { getCanvasMousePosition } from '../../common/ForgeViewer';
import { DraggableEdge, Edge, getPositionOnEdge } from './Edge';
import { Polygon } from './Polygon';
import {
  InitialShapeDrawingState,
  PolygonShapeDrawingResult,
  ShapeDrawingMode,
} from './ShapeDrawing';
import { DraggableVertex } from './Vertex';
import {
  PolygonDrawingAction,
  PolygonDrawingActionType,
  PolygonDrawingState,
  polygonDrawingReducer,
} from './polygon-drawing-reducer';
import { getRingsFromIntersections } from './shape-intersection';
import {
  LinearRing2WithId,
  LinearRing3WithId,
  Point3WithId,
  createIntermediateEdge,
  createMultipolygon,
  generateIdForRing,
  generateIdsForEdges,
  generateIdsForPoints,
  getArcPoints,
  getPolygon2PathData,
  getPositions,
  getSnapPoint,
  intermediateEdgeId,
  isPrimaryMouseButton,
  projectInDOM,
  projectMouseOnPlane,
  toPoints2WithId,
  transformPolygonCoordinates,
  useCameraChangedEvent,
  useSnapper,
  useViewerClickEvent,
} from './util';

function initializeState(
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.Polygon]
): PolygonDrawingState {
  if (!initialState) {
    return {
      completedRings: [],
      completedRings2: [],
      incompleteRing: [],
      incompleteRing2: [],
      mousePointerPoint: null,
      plane: null,
      intersectionsForIncompleteRing: new Map(),
      intersectionsForCompletedRings: new Map(),
    };
  }
  const completedRings = initialState.multipolygon.polygons.flatMap(
    (polygon) => [
      generateIdForRing(generateIdsForPoints(polygon.exterior)),
      ...polygon.interiors.map((interior) =>
        generateIdForRing(generateIdsForPoints(interior))
      ),
    ]
  );
  const completedRings2: LinearRing2WithId[] = completedRings.map((ring) => ({
    id: ring.id,
    linearRing: toPoints2WithId(ring.linearRing, initialState.plane),
  }));
  return {
    completedRings,
    completedRings2,
    plane: initialState.plane,
    incompleteRing: [],
    incompleteRing2: [],
    mousePointerPoint: null,
    intersectionsForIncompleteRing: new Map(),
    intersectionsForCompletedRings: new Map(),
  };
}

export const PolygonShapeDrawing = ({
  initialState,
  viewer,
  onResult,
  isOneClickShapeMode,
}: {
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.Polygon];
  viewer: Autodesk.Viewing.GuiViewer3D;
  onResult: (result: PolygonShapeDrawingResult) => void;
  // For now we just reuse the same tool, consider splitting it into two
  isOneClickShapeMode: boolean;
}) => {
  const [state, dispatch] = useReducer(
    polygonDrawingReducer,
    initialState,
    initializeState
  );

  const [isDragging, setIsDragging] = useState(false);

  const snapper = useSnapper(viewer);

  const emitResult = useCallback(() => {
    const hasNoIntersections = [
      ...state.intersectionsForCompletedRings.values(),
    ].every((intersections) => intersections.length === 0);
    const hasNoNewPoints = state.incompleteRing.length === 0;
    const hasConfirmedRing = state.completedRings2.length > 0;

    const isValidResult =
      hasNoIntersections && hasNoNewPoints && hasConfirmedRing;

    if (state.plane && isValidResult && !isDragging) {
      const ringPositions = state.completedRings2.map((ring) =>
        getPositions(ring.linearRing)
      );
      onResult({
        valid: true,
        multipolygon: to3dMultiPolygon(
          createMultipolygon(ringPositions),
          state.plane
        ),
        plane: state.plane,
      });
    } else {
      onResult({
        valid: false,
      });
    }
  }, [
    isDragging,
    onResult,
    state.completedRings2,
    state.incompleteRing.length,
    state.intersectionsForCompletedRings,
    state.plane,
  ]);

  useEffect(() => {
    if (!isDragging) {
      emitResult();
    }
  }, [emitResult, isDragging]);

  const getPoint = useCallback(
    (event: MouseEvent): Point | null => {
      let point: Point;

      if (state.plane) {
        if (snapper.isSnapped()) {
          point = projectPointOnPlane(
            getSnapPoint(snapper, viewer),
            state.plane
          );
          snapper.indicator.render();
        } else {
          point = projectMouseOnPlane(
            event.clientX,
            event.clientY,
            state.plane,
            viewer
          );
        }
      } else if (snapper.isSnapped()) {
        point = getSnapPoint(snapper, viewer);
        snapper.indicator.render();
      } else {
        return null;
      }

      return point;
    },
    [snapper, state.plane, viewer]
  );

  return (
    <>
      <CompletedRings
        state={state}
        dispatch={dispatch}
        getPoint={getPoint}
        setIsDragging={setIsDragging}
        isOneClickShapeMode={isOneClickShapeMode}
        viewer={viewer}
        snapper={snapper}
      />
      <IncompleteRing
        state={state}
        dispatch={dispatch}
        getPoint={getPoint}
        isDragging={isDragging}
        setIsDragging={setIsDragging}
        viewer={viewer}
        snapper={snapper}
        isOneClickShapeMode={isOneClickShapeMode}
      />
    </>
  );
};

function IncompleteRing({
  state,
  dispatch,
  getPoint,
  isDragging,
  setIsDragging,
  isOneClickShapeMode,
  viewer,
  snapper,
}: {
  state: PolygonDrawingState;
  dispatch: Dispatch<PolygonDrawingAction>;
  getPoint: (event: MouseEvent) => Point | null;
  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => void;
  isOneClickShapeMode: boolean;
  viewer: Autodesk.Viewing.GuiViewer3D;
  snapper: Autodesk.Viewing.Extensions.Snapping.Snapper;
}) {
  // To avoid triggering click events when double clicking
  const clickTimeoutRef = useRef<number>();
  const edgesForIncompleteRing = useMemo(
    () => generateIdsForEdges(state.incompleteRing),
    [state.incompleteRing]
  );
  const edgesForIncompleteRing2 = useMemo(
    () => generateIdsForEdges(state.incompleteRing2),
    [state.incompleteRing2]
  );

  const enableMagicShapeIntersections = useFlag(
    'enable-magic-shape-intersections'
  );

  const shouldAddMousePointerEdge =
    !!state.mousePointerPoint && state.incompleteRing.length > 0;

  const intersectionsForIncompleteRingIncludingMouseEdge = useMemo(() => {
    let result = state.intersectionsForIncompleteRing;
    if (state.plane && state.mousePointerPoint && shouldAddMousePointerEdge) {
      // Find intersections with the edge between the last confirmed point and the mouse edge
      result = copy(result);
      const intermediateEdge = createIntermediateEdge(
        state.incompleteRing2,
        toPlanarPoint(state.mousePointerPoint, state.plane)
      );
      addEdge(result, edgesForIncompleteRing2, intermediateEdge);
    }
    return result;
  }, [
    edgesForIncompleteRing2,
    shouldAddMousePointerEdge,
    state.incompleteRing2,
    state.intersectionsForIncompleteRing,
    state.mousePointerPoint,
    state.plane,
  ]);

  const invalidEdgesForIncompleteRing = useMemo(() => {
    return getInvalidEdges(
      edgesForIncompleteRing2,
      intersectionsForIncompleteRingIncludingMouseEdge
    );
  }, [
    edgesForIncompleteRing2,
    intersectionsForIncompleteRingIncludingMouseEdge,
  ]);

  const doesIncompleteRingFormAValidPolygon = useMemo(() => {
    // Valid if we have 2 points and a mouse pointer point, or if we have 3 points

    const numPoints = state.mousePointerPoint
      ? state.incompleteRing2.length + 1
      : state.incompleteRing2.length;

    if (numPoints < 3) {
      return false;
    }
    if (invalidEdgesForIncompleteRing.length > 0) {
      return false;
    }
    if (!state.plane) {
      return false;
    }
    if (state.mousePointerPoint) {
      // check intersection from the mouse pointer point to the first point
      const edgeFromMousePoint: LineSegment2 = [
        toPlanarPoint(state.mousePointerPoint, state.plane),
        state.incompleteRing2[0].point,
      ];
      return !edgesForIncompleteRing2.some((edge) =>
        intersects(edgeFromMousePoint, edge.segment)
      );
    } else {
      // check intersection from the last point to the first point
      const edgeFromLastPoint: LineSegment2 = [
        state.incompleteRing2[state.incompleteRing2.length - 1].point,
        state.incompleteRing2[0].point,
      ];
      return !edgesForIncompleteRing2.some((edge) =>
        intersects(edgeFromLastPoint, edge.segment)
      );
    }
  }, [
    edgesForIncompleteRing2,
    invalidEdgesForIncompleteRing.length,
    state.incompleteRing2,
    state.mousePointerPoint,
    state.plane,
  ]);

  const isAltKeyPressed = isHotkeyPressed('alt');

  const handleViewerClick = useCallback(
    (event: MouseEvent): boolean => {
      if (!isPrimaryMouseButton(event.button)) {
        return false;
      }
      if (isOneClickShapeMode) {
        const oneClickShape = getShape(viewer, event);

        if (oneClickShape) {
          if (enableMagicShapeIntersections) {
            getRingsFromIntersections(oneClickShape, viewer)
              .then((ringsFromIntersections) => {
                dispatch({
                  type: PolygonDrawingActionType.ADD_ONE_CLICK_SHAPE,
                  rings: ringsFromIntersections
                    ? ringsFromIntersections
                    : oneClickShape.rings,
                  plane: oneClickShape.plane,
                });
              })
              .catch(() => {
                console.warn('Failed to get ring from intersections');
              });
          } else {
            dispatch({
              type: PolygonDrawingActionType.ADD_ONE_CLICK_SHAPE,
              rings: oneClickShape.rings,
              plane: oneClickShape.plane,
            });
          }
        }

        return true;
      }

      const newPoint = getPoint(event);
      if (newPoint) {
        dispatch({
          type: PolygonDrawingActionType.ADD_INCOMPLETE_RING_POINT,
          position: newPoint,
          isAltKeyPressed,
        });
      }

      return true; // Stop the event from going to other tools in the stack
    },
    [
      isOneClickShapeMode,
      getPoint,
      viewer,
      enableMagicShapeIntersections,
      dispatch,
      isAltKeyPressed,
    ]
  );

  useViewerClickEvent(viewer, handleViewerClick);

  const handlePointerMove = useCallback(
    (event: PointerEvent) => {
      snapper.indicator.clearOverlays();
      snapper.indicator.render();
      if (isOneClickShapeMode) {
        dispatch({
          type: PolygonDrawingActionType.SET_MOUSE_POINTER_POINT,
          point: null,
        });
        return;
      }

      if (state.incompleteRing.length === 0 || isDragging) {
        return;
      }
      const newPoint = getPoint(event);
      dispatch({
        type: PolygonDrawingActionType.SET_MOUSE_POINTER_POINT,
        point: newPoint,
      });
    },
    [
      dispatch,
      getPoint,
      isDragging,
      isOneClickShapeMode,
      snapper.indicator,
      state.incompleteRing.length,
    ]
  );

  useEffect(() => {
    viewer.container.addEventListener('pointermove', handlePointerMove);
    return () => {
      viewer.container.removeEventListener('pointermove', handlePointerMove);
    };
  }, [handlePointerMove, snapper.indicator, viewer]);

  const handleVertexDrag = (event: MouseEvent, pointId: string) => {
    const { canvasX, canvasY } = getCanvasMousePosition(
      event.clientX,
      event.clientY
    );
    snapper.onMouseMove({
      x: canvasX,
      y: canvasY,
    });
    snapper.indicator.render();
    const position = getPoint(event);
    if (!position) {
      return;
    }
    setIsDragging(true);
    dispatch({
      type: PolygonDrawingActionType.MOVE_INCOMPLETE_RING_POINT,
      position,
      pointId,
    });
    if (event.type === 'pointerup') {
      setIsDragging(false);
    }
  };

  const handleEdgeDrag = (event: React.MouseEvent, edgeId: string) => {
    if (!state.plane) {
      return;
    }
    setIsDragging(true);
    const startVector = projectMouseOnPlane(
      event.clientX - event.movementX,
      event.clientY - event.movementY,
      state.plane,
      viewer
    );
    const translationVector = math.subtract(
      projectMouseOnPlane(event.clientX, event.clientY, state.plane, viewer),
      startVector
    );
    const edgeIndex = edgesForIncompleteRing.findIndex(
      (edge) => edge.id === edgeId
    );
    const startPointId = state.incompleteRing[edgeIndex].id;

    dispatch({
      type: PolygonDrawingActionType.MOVE_INCOMPLETE_RING_EDGE,
      startPositionTranslation: translationVector,
      endPositionTranslation: translationVector,
      startPointId,
    });

    if (event.type === 'pointerup') {
      setIsDragging(false);
    }
  };

  const handleFirstVertexClick = (event: React.MouseEvent) => {
    const handleFirstVertexClickThrottled = () => {
      if (
        !state.plane ||
        state.incompleteRing.length < 3 ||
        event.button !== 0 ||
        event.detail > 1
      ) {
        return;
      }
      dispatch({ type: PolygonDrawingActionType.CLOSE_RING });
    };

    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current); // Clear any existing timeout
    }
    // Wait before executing single click action
    const newTimeout = window.setTimeout(handleFirstVertexClickThrottled, 300);

    clickTimeoutRef.current = newTimeout;
  };

  const handleVertexDoubleClick = (
    event: React.MouseEvent,
    pointId: string
  ) => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current); // Clear the single click timeout
    }
    if (event.button !== 0) {
      return;
    }
    dispatch({
      type: PolygonDrawingActionType.REMOVE_INCOMPLETE_RING_POINT,
      pointId,
    });
  };

  const handleEdgeDoubleClick = (event: React.MouseEvent, edgeId: string) => {
    if (event.button !== 0) {
      return;
    }
    const edgeIndex = edgesForIncompleteRing.findIndex(
      (edge) => edge.id === edgeId
    );
    const startPointId = state.incompleteRing[edgeIndex].id;
    const newPoint = getPositionOnEdge(
      event,
      edgesForIncompleteRing[edgeIndex].segment,
      viewer
    );

    dispatch({
      type: PolygonDrawingActionType.SPLIT_INCOMPLETE_RING_EDGE,
      splitPosition: newPoint,
      startPointId,
    });
  };

  useHotkeys(
    ['enter'],
    () => dispatch({ type: PolygonDrawingActionType.CLOSE_RING }),
    {
      preventDefault: true,
    }
  );

  const isIntermediateEdgeValid = useMemo(() => {
    const intersectionsForEdge =
      intersectionsForIncompleteRingIncludingMouseEdge.get(intermediateEdgeId);
    if (!intersectionsForEdge) {
      return true;
    }
    return intersectionsForEdge.length === 0;
  }, [intersectionsForIncompleteRingIncludingMouseEdge]);

  const incompletePolygon: MultiPolygon | null = useMemo(() => {
    if (!doesIncompleteRingFormAValidPolygon || !state.plane) {
      return null;
    }
    const exterior = getPositions(state.incompleteRing2);
    if (shouldAddMousePointerEdge && state.mousePointerPoint) {
      const mousePointerPoint2 = toPlanarPoint(
        state.mousePointerPoint,
        state.plane
      );
      exterior.push(mousePointerPoint2);
    }
    exterior.push(state.incompleteRing2[0].point);
    const exterior3 = to3dPoints(exterior, state.plane);
    return {
      polygons: [
        {
          exterior: exterior3,
          interiors: [],
        },
      ],
    };
  }, [
    doesIncompleteRingFormAValidPolygon,
    shouldAddMousePointerEdge,
    state.incompleteRing2,
    state.mousePointerPoint,
    state.plane,
  ]);
  return (
    <>
      {incompletePolygon && state.plane && !isAltKeyPressed && (
        <Polygon
          multipolygon={incompletePolygon}
          plane={state.plane}
          viewer={viewer}
          isClosed={false}
        />
      )}
      {edgesForIncompleteRing.map(({ id, segment }, idx) => {
        const color = getEdgeColor(
          false,
          !invalidEdgesForIncompleteRing.includes(id)
        );
        if (idx === edgesForIncompleteRing.length - 1 && isAltKeyPressed)
          return;
        return (
          <DraggableEdge
            key={id}
            onDoubleClick={(event) => handleEdgeDoubleClick(event, id)}
            segment={segment}
            backgroundColor={color}
            viewer={viewer}
            onEdgeDrag={(event) => handleEdgeDrag(event, id)}
          />
        );
      })}
      {shouldAddMousePointerEdge
        ? IntermediateEdges(
            state.mousePointerPoint as Point,
            state.incompleteRing,
            state.plane,
            isAltKeyPressed && state.incompleteRing.length > 1,
            viewer,
            getEdgeColor(false, isIntermediateEdgeValid)
          )
        : null}
      {state.incompleteRing.map(({ point, id: pointId }, index) => {
        return (
          <DraggableVertex
            key={pointId}
            theme={index === 0 ? 'inverted' : 'default'}
            point={point}
            viewer={viewer}
            onClick={
              index === 0 ? (event) => handleFirstVertexClick(event) : undefined
            }
            onDoubleClick={(event) => handleVertexDoubleClick(event, pointId)}
            onVertexDrag={(event) => handleVertexDrag(event, pointId)}
          />
        );
      })}
    </>
  );
}

export const IntermediateEdges = (
  intermediatePoint: Point,
  ring: Point3WithId[],
  plane: Plane | null,
  isArc: boolean,
  viewer: Autodesk.Viewing.Viewer3D,
  color: string
) => {
  if (isArc) {
    const intermediatePlane = plane
      ? plane
      : calculatePlaneEquation([
          ring[ring.length - 2].point,
          ring[ring.length - 1].point,
          intermediatePoint,
        ]);

    const startPoint2 = toPlanarPoint(
      ring[ring.length - 2].point,
      intermediatePlane
    );
    const endPoint2 = toPlanarPoint(
      ring[ring.length - 1].point,
      intermediatePlane
    );
    const intermediatePoint2 = toPlanarPoint(
      intermediatePoint,
      intermediatePlane
    );

    const arcPoints = to3dPoints(
      getArcPoints(startPoint2, endPoint2, intermediatePoint2),
      intermediatePlane
    );

    return arcPoints
      .slice(1)
      .map((point, index) => (
        <Edge
          key={index}
          segment={[arcPoints[index], point]}
          backgroundColor={color}
          pointerEvents="none"
          viewer={viewer}
        />
      ));
  }
  return (
    <Edge
      segment={[ring[ring.length - 1].point, intermediatePoint]}
      backgroundColor={color}
      viewer={viewer}
      pointerEvents="none"
    />
  );
};

function CompletedRings({
  state,
  dispatch,
  getPoint,
  setIsDragging,
  isOneClickShapeMode,
  viewer,
  snapper,
}: {
  state: PolygonDrawingState;
  dispatch: Dispatch<PolygonDrawingAction>;
  getPoint: (event: MouseEvent) => Point | null;
  setIsDragging: (isDragging: boolean) => void;
  isOneClickShapeMode: boolean;
  viewer: Autodesk.Viewing.GuiViewer3D;
  snapper: Autodesk.Viewing.Extensions.Snapping.Snapper;
}) {
  const isShiftPressed = isHotkeyPressed('shift');

  const edgesForCompletedRings = useMemo(
    () =>
      Object.fromEntries(
        state.completedRings.map((ring) => [
          ring.id,
          generateIdsForEdges(ring.linearRing),
        ])
      ),
    [state.completedRings]
  );

  const invalidEdgesForCompletedRings = useMemo(() => {
    return getInvalidEdges(
      Object.values(edgesForCompletedRings).flat(),
      state.intersectionsForCompletedRings
    );
  }, [edgesForCompletedRings, state.intersectionsForCompletedRings]);

  const completedMultipolygon: MultiPolygon | null = useMemo(() => {
    if (state.completedRings2.length === 0 || !state.plane) {
      return null;
    }
    const rings = state.completedRings2.map((ring) => ring.linearRing);
    // rings without intersections
    const validRings = rings
      .filter((ring) => {
        const edges = generateIdsForEdges(ring);
        return (
          getInvalidEdges(edges, state.intersectionsForCompletedRings)
            .length === 0
        );
      })
      .map((ring) => getPositions(ring));
    return to3dMultiPolygon(createMultipolygon(validRings), state.plane);
  }, [
    state.completedRings2,
    state.intersectionsForCompletedRings,
    state.plane,
  ]);

  const handleVertexDrag = (
    event: MouseEvent,
    pointId: string,
    ringId: string
  ) => {
    setIsDragging(true);
    const { canvasX, canvasY } = getCanvasMousePosition(
      event.clientX,
      event.clientY
    );
    snapper.onMouseMove({
      x: canvasX,
      y: canvasY,
    });
    snapper.indicator.render();
    const position = getPoint(event);

    if (!position) {
      return;
    }
    dispatch({
      type: PolygonDrawingActionType.MOVE_COMPLETE_RING_POINT,
      position,
      pointId,
      ringId,
    });
    if (event.type === 'pointerup') {
      setIsDragging(false);
    }
  };

  const handleEdgeDrag = (
    event: React.MouseEvent,
    edgeId: string,
    ringId: string
  ) => {
    if (!state.plane) {
      return;
    }
    setIsDragging(true);
    const startVector = projectMouseOnPlane(
      event.clientX - event.movementX,
      event.clientY - event.movementY,
      state.plane,
      viewer
    );

    const edgeIndex = edgesForCompletedRings[ringId].findIndex(
      (edge) => edge.id === edgeId
    );
    const startPointId = state.completedRings.find((ring) => ring.id === ringId)
      ?.linearRing[edgeIndex].id;

    invariant(startPointId, 'start point not found');

    const draggingVector = math.subtract(
      projectMouseOnPlane(event.clientX, event.clientY, state.plane, viewer),
      startVector
    );

    let startPositionTranslation: Point;
    let endPositionTranslation: Point;

    if (!isShiftPressed) {
      const ring = state.completedRings.find(
        (ring) => ring.id === ringId
      )?.linearRing;

      invariant(ring, 'ring not found');

      const newRing = [...ring];

      const startPointIndex = newRing.findIndex(
        (point) => point.id === startPointId
      );

      // Get the start and end points of the dragged edge
      const startPoint = newRing[startPointIndex].point;
      const endPoint = newRing[(startPointIndex + 1) % newRing.length].point;

      // Get points connected to the start and end points
      const prevPoint =
        newRing[
          startPointIndex - 1 < 0 ? newRing.length - 2 : startPointIndex - 1
        ].point;
      const nextPoint =
        newRing[
          startPointIndex + 2 > newRing.length - 1 ? 1 : startPointIndex + 2
        ].point;

      // Calculate projection of draggingVector onto the lines defined by connected points
      startPositionTranslation = projectVectorOntoLine(
        draggingVector,
        math.subtract(startPoint, prevPoint)
      );
      endPositionTranslation = projectVectorOntoLine(
        draggingVector,
        math.subtract(nextPoint, endPoint)
      );
    } else {
      startPositionTranslation = draggingVector;
      endPositionTranslation = draggingVector;
    }

    dispatch({
      type: PolygonDrawingActionType.MOVE_COMPLETE_RING_EDGE,
      startPositionTranslation: startPositionTranslation,
      endPositionTranslation: endPositionTranslation,
      startPointId,
      ringId,
    });

    if (event.type === 'pointerup') {
      setIsDragging(false);
    }
  };

  function projectVectorOntoLine(vector: Point, line: Point) {
    const normLine = math.norm(line);
    if (normLine === 0) return [0, 0, 0] as Point;
    const lineUnitVector = math.divide(line, normLine) as Point;
    const projectionLength = math.dot(vector, lineUnitVector);
    return math.multiply(
      math.round(lineUnitVector, 1),
      projectionLength
    ) as Point;
  }

  const handleEdgeDoubleClick = (
    event: React.MouseEvent,
    edgeId: string,
    ringId: string
  ) => {
    if (event.button !== 0) {
      return;
    }

    const edgeIndex = edgesForCompletedRings[ringId].findIndex(
      (edge) => edge.id === edgeId
    );
    const startPointId = state.completedRings.find((ring) => ring.id === ringId)
      ?.linearRing[edgeIndex].id;
    invariant(startPointId, 'start point not found');
    const newPoint = getPositionOnEdge(
      event,
      edgesForCompletedRings[ringId][edgeIndex].segment,
      viewer
    );
    dispatch({
      type: PolygonDrawingActionType.SPLIT_COMPLETE_RING_EDGE,
      splitPosition: newPoint,
      startPointId,
      ringId,
    });
  };

  const handleRemoveRing = (ringId: string) => {
    dispatch({
      type: PolygonDrawingActionType.REMOVE_COMPLETE_RING,
      ringId,
    });
  };

  return (
    <>
      {isOneClickShapeMode && completedMultipolygon && state.plane ? (
        <MultipolygonHoles
          originalRings={state.completedRings}
          multipolygon={completedMultipolygon}
          plane={state.plane}
          viewer={viewer}
          onHoleClick={(ringId) => handleRemoveRing(ringId)}
        />
      ) : null}
      {completedMultipolygon && state.plane && (
        <Polygon
          multipolygon={completedMultipolygon}
          plane={state.plane}
          viewer={viewer}
          isClosed={true}
        />
      )}
      {Object.entries(edgesForCompletedRings).map(([ringId, edgesForRing]) =>
        edgesForRing.map(({ id: edgeId, segment }) => {
          const color = getEdgeColor(
            true,
            !invalidEdgesForCompletedRings.includes(edgeId)
          );
          return (
            <DraggableEdge
              key={edgeId}
              onDoubleClick={(event) =>
                handleEdgeDoubleClick(event, edgeId, ringId)
              }
              onEdgeDrag={(event) => handleEdgeDrag(event, edgeId, ringId)}
              segment={segment}
              backgroundColor={color}
              viewer={viewer}
            />
          );
        })
      )}

      {state.completedRings.map((ring) => {
        return ring.linearRing.map(({ point, id: pointId }, index) => {
          if (index === ring.linearRing.length - 1) {
            return null;
          }
          return (
            <DraggableVertex
              key={pointId}
              theme={index === 0 ? 'inverted' : 'default'}
              point={point}
              viewer={viewer}
              onDoubleClick={() => {
                dispatch({
                  type: PolygonDrawingActionType.REMOVE_COMPLETE_RING_POINT,
                  ringId: ring.id,
                  pointId,
                });
              }}
              onVertexDrag={(event) =>
                handleVertexDrag(event, pointId, ring.id)
              }
            />
          );
        });
      })}
    </>
  );
}

const isEqualRings = (ring1: LinearRing, ring2: LinearRing) => {
  return (
    ring1.length === ring2.length &&
    ring1.every(
      (point, index) => (math.distance(ring2[index], point) as number) < 0.0001
    )
  );
};

// A component for filling the holes of a multipolygon in one click shape mode
const MultipolygonHoles = ({
  originalRings,
  multipolygon,
  viewer,
  onHoleClick,
}: {
  originalRings: LinearRing3WithId[];
  multipolygon: MultiPolygon;
  plane: Plane;
  viewer: Autodesk.Viewing.GuiViewer3D;
  onHoleClick: (ringId: string) => void;
}) => {
  // Just use an empty object so we can notify the component when the camera changes
  const [cameraPosition, setCameraPosition] = useState({});

  useCameraChangedEvent(
    viewer,
    useCallback(() => {
      setCameraPosition({});
    }, [])
  );

  const holes: MultiPolygon = useMemo(() => {
    return {
      polygons: multipolygon.polygons.flatMap((polygon) =>
        polygon.interiors.map((interior) => ({
          exterior: interior,
          interiors: [],
        }))
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipolygon, cameraPosition]);

  const ringsWithPathData = useMemo(() => {
    return holes.polygons.map((polygon) => ({
      ring: polygon.exterior,
      pathData: getPolygon2PathData(
        transformPolygonCoordinates(polygon, (p) => projectInDOM(p, viewer))
      ),
    }));
  }, [holes.polygons, viewer]);

  // Find back to the original ring matching the clicked ring
  const handleHoleClick = (ring: LinearRing) => {
    const ringId = originalRings.find((originalRing) =>
      isEqualRings(ring, getPositions(originalRing.linearRing))
    )?.id;
    if (ringId) {
      onHoleClick(ringId);
    } else {
      console.warn('Could not find ring id for clicked hole');
    }
  };

  return (
    <chakra.svg
      w="100%"
      h="100%"
      position={'absolute'}
      zIndex={1}
      pointerEvents={'none'}
    >
      <chakra.g
        pointerEvents={'auto'}
        opacity={0.8}
        fill="gray.500"
        cursor="pointer"
      >
        {ringsWithPathData.map(({ ring, pathData }, index) => (
          <chakra.path
            key={index}
            d={pathData}
            _hover={{
              filter: 'brightness(150%)',
            }}
            onClick={() => handleHoleClick(ring)}
          />
        ))}
      </chakra.g>
    </chakra.svg>
  );
};

const getEdgeColor = (isClosed?: boolean, isValid?: boolean) => {
  if (!isValid) {
    return 'red.400';
  } else if (isClosed) {
    return 'green.500';
  } else {
    return 'yellow.300';
  }
};
