import { useCallback, useEffect, useState } from 'react';
import {
  MultiPolygon2,
  Point2,
} from '../../../domain/geometry/geometric-types';

import {
  Viewport,
  getPointInPdfCoordinateSystem,
  getPointInViewerCoordinateSystem,
  useSheetViewer,
} from '../../common/SheetViewer';
import { SheetLineShapeDrawing } from './SheetLineShapeDrawing';
import { SheetMagicShapeDrawing } from './SheetMagicShapeDrawing';
import { SheetPointShapeDrawing } from './SheetPointShapeDrawing';
import { SheetPolygonShapeDrawing } from './SheetPolygonShapeDrawing';
import { SheetRectangleShapeDrawing } from './SheetRectangleShapeDrawing';
import {
  Diamond,
  KileTypeSKU,
  SheetWedgeShapeDrawing,
} from './SheetWedgeShapeDrawing';

export enum ShapeDrawingResultType {
  Invalid = 'Invalid',
  LineString = 'LineString',
  Polygon = 'Polygon',
  Wedge = 'Wedge',
  Point = 'Point',
}
export enum ShapeDrawingMode {
  LineString = 'line-string',
  Wedges = 'wedge',
  Polygon = 'polygon',
  Rectangle = 'rectangle',
  MagicPolygon = 'magic-polygon',
  Point = 'point',
}

export type LineStringShapeDrawingResult = {
  points: Point2[];
  valid: boolean;
};

export type WedgesDrawingResult = {
  points: Point2[];
  valid: boolean;
  diamond: Diamond[];
  kileType: KileTypeSKU[];
};

export type PolygonShapeDrawingResult =
  | {
      valid: true;
      multipolygon: MultiPolygon2;
    }
  | {
      valid: false;
    };

export type RectangleShapeDrawingResult = {
  points: Point2[];
  valid: boolean;
};

export type PointDrawingResult = {
  points: Point2[];
  valid: boolean;
};

export type ShapeDrawingResult =
  | ({
      type: ShapeDrawingResultType.LineString;
    } & LineStringShapeDrawingResult)
  | ({
      type: ShapeDrawingResultType.Polygon;
    } & PolygonShapeDrawingResult)
  | ({
      type: ShapeDrawingResultType.Wedge;
    } & WedgesDrawingResult)
  | ({
      type: ShapeDrawingResultType.Point;
    } & PointDrawingResult);

export type InitialShapeDrawingState = {
  [ShapeDrawingMode.LineString]: {
    points: Point2[];
  };
  [ShapeDrawingMode.Wedges]: {
    points: Point2[];
  };
  [ShapeDrawingMode.Polygon]: {
    multipolygon: MultiPolygon2;
  };
  [ShapeDrawingMode.Rectangle]: {
    startPoint: Point2 | null;
    endPoint: Point2 | null;
  };
  [ShapeDrawingMode.MagicPolygon]: {
    multipolygon: MultiPolygon2;
  };
  [ShapeDrawingMode.Point]: {
    point: Point2[];
    updating: boolean;
  };
};

export type ShapeDrawingProps<Q extends ShapeDrawingMode> = {
  onResult: (result: ShapeDrawingResult) => void;
  mode: Q;
  initialState?: InitialShapeDrawingState[Q];
};

export const ShapeDrawing = <Q extends ShapeDrawingMode>({
  mode,
  onResult,
  initialState,
}: ShapeDrawingProps<Q>) => {
  const onPolygonResult = useCallback(
    (result: PolygonShapeDrawingResult) => {
      onResult({ type: ShapeDrawingResultType.Polygon, ...result });
    },
    [onResult]
  );

  const onLineStringResult = useCallback(
    (result: LineStringShapeDrawingResult) => {
      onResult({ type: ShapeDrawingResultType.LineString, ...result });
    },
    [onResult]
  );
  const onWedgeResult = useCallback(
    (result: WedgesDrawingResult) => {
      onResult({ type: ShapeDrawingResultType.Wedge, ...result });
    },
    [onResult]
  );

  const onPointResult = useCallback(
    (result: PointDrawingResult) => {
      onResult({ type: ShapeDrawingResultType.Point, ...result });
    },
    [onResult]
  );

  const {
    viewerRef,
    page,
    addViewportChangeHandler,
    removeViewportChangeHandler,
  } = useSheetViewer();

  const [viewerViewport, setViewerViewport] = useState<Viewport | null>(null);

  useEffect(() => {
    const onViewportChange = (vp: Viewport) => {
      setViewerViewport(vp);
    };
    addViewportChangeHandler(onViewportChange);
    return () => {
      removeViewportChangeHandler(onViewportChange);
    };
  }, [addViewportChangeHandler, removeViewportChangeHandler]);

  const _getPointInPdfCoordinateSystem = useCallback(
    (pointInDom: Point2): Point2 => {
      const viewerElement = viewerRef.current;
      if (!viewerViewport || !page || !viewerElement) {
        throw new Error('Viewer not ready');
      }

      return getPointInPdfCoordinateSystem(
        pointInDom,
        viewerViewport.offset,
        viewerViewport.scale,
        page,
        viewerElement
      );
    },
    [viewerViewport, page, viewerRef]
  );

  const _getPointInDomCoordinateSystem = useCallback(
    (pointInDom: Point2) => {
      if (!viewerViewport || !page || !viewerRef.current) {
        throw new Error('Viewer not ready');
      }
      const viewerPoint = getPointInViewerCoordinateSystem(
        pointInDom,
        viewerViewport.offset,
        viewerViewport.scale,
        page
      );
      const { top, left } = viewerRef.current.getBoundingClientRect();
      return [viewerPoint[0] + left, viewerPoint[1] + top] as Point2;
    },
    [viewerViewport, page, viewerRef]
  );

  if (!viewerRef.current || !page || !viewerViewport) {
    return null;
  }

  const element = (
    <>
      {mode === ShapeDrawingMode.Polygon && (
        <SheetPolygonShapeDrawing
          initialState={
            // Not sure why type assertion is needed here
            initialState as InitialShapeDrawingState[ShapeDrawingMode.Polygon]
          }
          onResult={onPolygonResult}
          getPointInPdfCoordinateSystem={_getPointInPdfCoordinateSystem}
          getPointInDomCoordinateSystem={_getPointInDomCoordinateSystem}
        />
      )}
      {mode === ShapeDrawingMode.MagicPolygon && (
        <SheetMagicShapeDrawing
          initialState={
            initialState as InitialShapeDrawingState[ShapeDrawingMode.MagicPolygon]
          }
          onResult={onPolygonResult}
          getPointInPdfCoordinateSystem={_getPointInPdfCoordinateSystem}
          getPointInDomCoordinateSystem={_getPointInDomCoordinateSystem}
          viewerViewport={viewerViewport}
        />
      )}
      {mode === ShapeDrawingMode.Rectangle && (
        <SheetRectangleShapeDrawing
          initialState={
            initialState as InitialShapeDrawingState[ShapeDrawingMode.Rectangle]
          }
          onResult={onPolygonResult}
          getPointInPdfCoordinateSystem={_getPointInPdfCoordinateSystem}
          getPointInDomCoordinateSystem={_getPointInDomCoordinateSystem}
        />
      )}
      {mode === ShapeDrawingMode.Point && (
        <SheetPointShapeDrawing
          initialState={
            initialState as InitialShapeDrawingState[ShapeDrawingMode.Point]
          }
          onResult={onPointResult}
          getPointInPdfCoordinateSystem={_getPointInPdfCoordinateSystem}
          getPointInDomCoordinateSystem={_getPointInDomCoordinateSystem}
        />
      )}
      {mode === ShapeDrawingMode.LineString && (
        <SheetLineShapeDrawing
          initialState={
            initialState as InitialShapeDrawingState[ShapeDrawingMode.LineString]
          }
          onResult={onLineStringResult}
          getPointInPdfCoordinateSystem={_getPointInPdfCoordinateSystem}
          getPointInDomCoordinateSystem={_getPointInDomCoordinateSystem}
        />
      )}
      {mode === ShapeDrawingMode.Wedges && (
        <SheetWedgeShapeDrawing
          initialState={
            initialState as InitialShapeDrawingState[ShapeDrawingMode.Wedges]
          }
          onResult={onWedgeResult}
          getPointInPdfCoordinateSystem={_getPointInPdfCoordinateSystem}
          getPointInDomCoordinateSystem={_getPointInDomCoordinateSystem}
        />
      )}
    </>
  );

  return element;
};
