import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
  RefObject,
  useEffect,
  useCallback,
} from 'react';

interface ExportToPdfContextType {
  svgRef: RefObject<SVGSVGElement> | null;
  addSvgRef: (ref: RefObject<SVGSVGElement>) => void;
  clearSvgRefs: () => void;
}

const ExportToPdfContext = createContext<ExportToPdfContextType | undefined>(
  undefined
);

export const ExportToPdfProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const combinedSvgRef = useRef<SVGSVGElement | null>(null);
  const [svgRefs, setSvgRefs] = useState<RefObject<SVGSVGElement>[]>([]);

  const addSvgRef = useCallback((ref: RefObject<SVGSVGElement>) => {
    setSvgRefs((prevRefs) => [...prevRefs, ref]);
  }, []);

  const clearSvgRefs = useCallback(() => {
    setSvgRefs([]);
  }, []);

  useEffect(() => {
    // Create an SVG element and add it to the DOM
    const newSvgElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );

    newSvgElement.style.position = 'absolute';
    newSvgElement.style.top = '-9999px';
    newSvgElement.style.left = '-9999px';

    document.body.appendChild(newSvgElement);
    combinedSvgRef.current = newSvgElement;

    return () => {
      if (
        combinedSvgRef.current &&
        document.body.contains(combinedSvgRef.current)
      ) {
        document.body.removeChild(combinedSvgRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!combinedSvgRef.current) return;

    // Clear the combined SVG before adding new children
    while (combinedSvgRef.current.firstChild) {
      combinedSvgRef.current.removeChild(combinedSvgRef.current.firstChild);
    }

    // Append cloned SVG elements as children to the combined SVG
    svgRefs.forEach((svgRef) => {
      if (svgRef.current) {
        const clonedSvg = svgRef.current.cloneNode(true) as SVGSVGElement;
        combinedSvgRef.current?.appendChild(clonedSvg);
      }
    });

    // Ensure no updates are made to svgRefs or combinedSvgRef during this effect
  }, [svgRefs, combinedSvgRef]);

  return (
    <ExportToPdfContext.Provider
      value={{ svgRef: combinedSvgRef, addSvgRef, clearSvgRefs }}
    >
      {children}
    </ExportToPdfContext.Provider>
  );
};

export const useExportToPdf = (): ExportToPdfContextType => {
  const context = useContext(ExportToPdfContext);
  if (!context) {
    throw new Error(
      'useExportToPdf must be used within an ExportToPdfProvider'
    );
  }
  return context;
};
