import { HeadObjectCommand } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { useCallback, useMemo, useState } from 'react';
import { pdfjs } from 'react-pdf';
import invariant from 'tiny-invariant';
import { CombinedError, useMutation, useQuery } from 'urql';
import awsExports from '../aws-exports';
import {
  CreateSheetDocument,
  GetActiveSheetsForProjectDocument,
  GetMagicSheetShapeDocument,
  SheetsDeepFragment,
} from '../gql/graphql';
import { useUserTenant } from '../services/auth-info';
import { useS3Client } from './s3';

export type Sheet = SheetsDeepFragment & { s3Key: string };

type UseSheetReturn = {
  loading: boolean;
  error: CombinedError | undefined;
  submitting: boolean;
  sheets: Sheet[] | null;
  createSheet: (file: File, name: string, version: number) => Promise<void>;
};

const generateS3KeyForSheet = (
  tenant: string,
  projectId: string,
  filename: string
) => `${tenant}/project/${projectId}/sheets/1/${filename}`;

export function useSheets(projectId: string): UseSheetReturn {
  const { tenant: userTenant } = useUserTenant();
  const tenant = userTenant?.group;
  const s3Client = useS3Client(projectId);
  const [submitting, setSubmitting] = useState(false);
  const [{ data: sheetsData, fetching: fetchingSheets, error: sheetsError }] =
    useQuery({
      query: GetActiveSheetsForProjectDocument,
      variables: {
        projectId,
      },
    });
  const [, executeCreateSheetMutation] = useMutation(CreateSheetDocument);
  const [, getMagicSheetShapesMutation] = useMutation(
    GetMagicSheetShapeDocument
  );

  let sheets = useMemo(() => {
    if (!sheetsData?.project?.activeSheets || !tenant) {
      return null;
    }
    return sheetsData.project.activeSheets.map((sheet) => ({
      ...sheet,
      s3Key: generateS3KeyForSheet(tenant, sheet.projectId, sheet.filename),
    }));
  }, [sheetsData?.project?.activeSheets, tenant]);

  const createSheet = useCallback(
    async (file: File, name: string, version: number) => {
      invariant(awsExports.attachments_bucket_name, 'No bucket configured');
      invariant(tenant, 'No tenant configured');
      const key = generateS3KeyForSheet(tenant, projectId, file.name);
      try {
        setSubmitting(true);
        // Check that a file matching the name and version doesn't already exist
        console.log('checking if sheet exists');

        let sheetAlreadyExists: boolean;
        try {
          await s3Client.send(
            new HeadObjectCommand({
              Bucket: awsExports.attachments_bucket_name,
              Key: key,
            })
          );
          sheetAlreadyExists = true;
        } catch (e) {
          sheetAlreadyExists = false;
        }

        if (sheetAlreadyExists) {
          throw new Error(
            `A sheet with the name ${name} and version ${version} already exists. Use a different name or version.`
          );
        }

        const upload = new Upload({
          client: s3Client,
          params: {
            Bucket: awsExports.attachments_bucket_name,
            Key: key,
            Body: file,
            ContentType: 'application/pdf',
            Metadata: {
              name: encodeURIComponent(name),
              version: version.toString(),
              projectId,
            },
          },
        });
        // TODO: Add progress status subscription here

        await upload.done();
        const { data, error } = await executeCreateSheetMutation({
          input: {
            sheet: {
              projectId,
              name,
              filename: file.name,
              version,
            },
          },
        });
        // TODO: Graceful error handling - remove sheet from s3?
        if (error) {
          console.error('Create sheet mutation error', error);
          throw new Error('An unexpected error occurred when creating sheet');
        }

        // Low effort way of triggering embedding lambda for generating SAM embeddings on upload
        // Ideally, this should have been done with S3 notifications on upload,
        // but i ran into circular stack dependencies in CDK and ran out of time
        const generateEmbeddingsForSheet = async () => {
          const createdSheetId = data?.createSheet?.sheet?.id;
          const { numPages } = await pdfjs.getDocument(await file.arrayBuffer())
            .promise;
          if (numPages <= 5 && createdSheetId && tenant === 'Protan') {
            for (let pageNumber = 0; pageNumber < numPages; pageNumber++) {
              await getMagicSheetShapesMutation({
                input: {
                  sheetId: createdSheetId,
                  pageNumber,
                  mousePoints: [{ foreground: true, point: { x: 0, y: 0 } }],
                },
              });
            }
          }
        };
        generateEmbeddingsForSheet().catch((err) =>
          console.error('Error on generating embeddings for sheet', err)
        );
      } finally {
        setSubmitting(false);
      }
    },
    [
      executeCreateSheetMutation,
      getMagicSheetShapesMutation,
      projectId,
      s3Client,
      tenant,
    ]
  );

  return {
    sheets,
    error: sheetsError,
    loading: fetchingSheets,
    submitting,
    createSheet,
  };
}
