import { Auth } from '@aws-amplify/auth';
import { useToken } from '@chakra-ui/react';
import { print } from 'graphql';
import { isEqual, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import invariant from 'tiny-invariant';
import { Client, useClient, useQuery } from 'urql';

import { PropertyContext } from '../domain/property-operations';
import {
  ResolvedOrderEntry,
  resolveDbIds,
  resolveOrderEntry,
  resolveOrderEntryBatch,
} from '../domain/resolve-order-entry';

import {
  ElementIdentifierDbidType,
  GetOrderDeepDocument,
  GetProjectSettingsDocument,
  OrderDeepFragment,
  OrderEntryDeepFragment,
  OrderEntryOnOrderEntryForOrderEntriesOrderIdFkeyUsingOrderEntriesPkeyUpdate,
  OrderStatus,
  UnitSettingsType,
  UpdateOrderDocument,
  UpdateOrderInput,
} from '../gql/graphql';
import { customFetch } from '../urql';
import { intersects, merge } from '../utils/dbid-utils';
import { parseLabels } from '../utils/label-utils';
import { useUserTenant } from '../services/auth-info';
import { runWorkInBatches } from '../utils/promise-pool';
import { usePropertyContext } from './property-resolving';

export type ResolvedOrder = Omit<
  OrderDeepFragment,
  'orderEntries' | 'labels'
> & {
  orderEntries: ResolvedOrderEntry[];
  labels: { key: string; value: string }[];
};

type OrdersCache = {
  cachedResolvedOrders: Map<string, ResolvedOrder>;
  cachedOrders: Map<string, OrderDeepFragment>;
  propertyContext: PropertyContext | null;
  projectId: string | null;
};
const ordersCache: OrdersCache = {
  cachedResolvedOrders: new Map<string, ResolvedOrder>(),
  cachedOrders: new Map<string, OrderDeepFragment>(),
  propertyContext: null,
  projectId: null,
};
// Warning: Use with caution. Can be very slow
export function useResolvedOrders(
  projectId: string,
  orders: OrderDeepFragment[]
): {
  resolvedOrders: ResolvedOrder[] | null;
  isLoading: boolean;
  error: Error | null;
} {
  const client = useClient();
  const [resolvedOrders, setResolvedOrders] = useState<ResolvedOrder[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { propertyContext, isAllPropertiesLoaded, error } =
    usePropertyContext(projectId);

  const [{ data: projectSettingsData }] = useQuery({
    query: GetProjectSettingsDocument,
    variables: {
      projectId,
    },
  });

  const unitSettings = useMemo(() => {
    return projectSettingsData?.project?.settings
      ?.unitSettings as UnitSettingsType;
  }, [projectSettingsData]);

  const { tenant } = useUserTenant();

  useEffect(() => {
    async function resolve(ordersForResolution: Array<OrderDeepFragment>) {
      // Set the orders that we now have the cache for
      ordersForResolution.forEach((order) =>
        ordersCache.cachedOrders.set(order.id, order)
      );
      ordersCache.propertyContext = propertyContext;
      ordersCache.projectId = projectId;
      setIsLoading(true);
      if (isAllPropertiesLoaded) {
        try {
          const { results, errors } = await runWorkInBatches(
            ordersForResolution,
            (order) =>
              resolveOrder(client, order, propertyContext, false, unitSettings)
          );

          if (errors.length > 0) {
            console.error(errors);
          }

          results.forEach((resolvedOrder) =>
            ordersCache.cachedResolvedOrders.set(
              resolvedOrder.id,
              resolvedOrder
            )
          );

          results.forEach((resOrder) =>
            ordersCache.cachedResolvedOrders.set(resOrder.id, resOrder)
          );

          const newResolvedOrders = [
            ...ordersCache.cachedResolvedOrders.values(),
          ].filter((order) => order.projectId === projectId);

          setResolvedOrders(newResolvedOrders);
        } finally {
          setIsLoading(false);
        }
      }
    }

    const ordersToResolve = getOrdersToResolve(
      projectId,
      propertyContext,
      orders
    );

    if (ordersToResolve.length > 0) {
      resolve(ordersToResolve);
    } else {
      const cachedResolvedOrders = Array.from(
        ordersCache.cachedResolvedOrders.values()
      );
      // we are only interested in the cached orders for the current project
      const ordersToRestore = cachedResolvedOrders.filter(
        (order) => order.projectId === projectId
      );
      setResolvedOrders(ordersToRestore);
    }
  }, [
    isAllPropertiesLoaded,
    orders,
    projectId,
    propertyContext,
    client,
    unitSettings,
    tenant?.group,
  ]);
  return { resolvedOrders, isLoading, error: error ?? null };
}

const getOrdersToResolve = (
  projectId: string,
  propertyContext: PropertyContext,
  currentOrders: OrderDeepFragment[]
) => {
  const cachedOrdersLength = ordersCache.cachedOrders.size;
  const resolvedOrdersLength = ordersCache.cachedResolvedOrders.size;
  if (cachedOrdersLength <= 0 || resolvedOrdersLength <= 0) {
    return currentOrders;
  }
  if (projectId !== ordersCache.projectId) {
    ordersCache.cachedResolvedOrders = new Map<string, ResolvedOrder>();
    ordersCache.cachedOrders = new Map<string, OrderDeepFragment>();
    ordersCache.propertyContext = null;
    ordersCache.projectId = null;
    return currentOrders;
  }
  const cachedOrders = [...ordersCache.cachedOrders.values()];
  if (!isEqual(propertyContext, ordersCache.propertyContext)) {
    // Is it possible to associate the property context changing with a particular order entry instead of re-resolving all the orders?
    return currentOrders;
  }
  const prevOrderEntries = cachedOrders
    .map((order) => order.orderEntries)
    .flat();
  const currentOrderEntries = currentOrders
    .map((order) => order.orderEntries)
    .flat();
  const toReturn: Array<OrderDeepFragment> = [];
  currentOrderEntries.forEach((orderEntry) => {
    const found = prevOrderEntries.find((oe) => oe.id === orderEntry.id);
    const alreadyInToReturn = toReturn.find(
      (ret) => ret.id === orderEntry.orderId
    );
    if (!isEqual(found, orderEntry) && !alreadyInToReturn) {
      const correspondingOrder = currentOrders.find(
        (order) => order.id === orderEntry.orderId
      );
      toReturn.push(correspondingOrder!);
    }
    // Check if we have order entries in the cache that are not in the current order and remove them
    // Do we have order entries in the cache that are no longer part of the current order?
    // So we should check the prev order entries related to the current order
    prevOrderEntries
      .filter((poe) => poe.orderId === currentOrders[0].id)
      .forEach((prevEntry) => {
        const found = currentOrderEntries.find((oe) => oe.id === prevEntry.id);
        const alreadyInToReturn = toReturn.find(
          (ret) => ret.id === orderEntry.orderId
        );
        if (
          !found &&
          !alreadyInToReturn &&
          currentOrders[0].projectId === projectId
        ) {
          const correspondingOrder = currentOrders.find(
            (order) => order.id === orderEntry.orderId
          );
          // if (correspondingOrder) toReturn.push(correspondingOrder);

          // Update the cache instead of re-resolving the order
          const resolvedOrderInCache = ordersCache.cachedResolvedOrders.get(
            orderEntry.orderId
          )!;
          const orderEntriesToSave = resolvedOrderInCache.orderEntries.filter(
            (oe) => oe.id !== prevEntry.id
          );
          resolvedOrderInCache.orderEntries = orderEntriesToSave;
          const orderInCache = ordersCache.cachedOrders.get(orderEntry.orderId);
          orderInCache!.orderEntries = correspondingOrder!.orderEntries;
        }
      });
  });
  return toReturn;
};

export type OrderEntryWithOrder = {
  orderEntry: OrderEntryDeepFragment;
  order: OrderDeepFragment;
};

export type ResolvedDbIdsReturn = {
  getDbIdsForOrder: (orderId: string) => ElementIdentifierDbidType[];
  findOrdersMatchingDbIds: (
    searchDbIds: ElementIdentifierDbidType[]
  ) => OrderDeepFragment[];
  findOrderEntriesMatchingDbIds: (
    searchDbIds: ElementIdentifierDbidType[]
  ) => OrderEntryWithOrder[];
  isLoading: boolean;
};

export function useResolvedDbIds(
  projectId: string,
  orders: OrderDeepFragment[]
): ResolvedDbIdsReturn {
  const { propertyContext, isAllPropertiesLoaded, error } =
    usePropertyContext(projectId);

  const [resolvedOrders, setResolvedOrders] = useState<
    Map<string, ElementIdentifierDbidType[]>
  >(new Map());

  const [resolvedOrderEntries, setResolvedOrderEntries] = useState<
    Map<string, ElementIdentifierDbidType[]>
  >(new Map());

  const [orderMap, setOrderMap] = useState<Map<string, OrderDeepFragment>>(
    new Map()
  );

  const [orderEntryMap, setOrderEntryMap] = useState<
    Map<string, OrderEntryDeepFragment>
  >(new Map());

  const [orderEntryToOrderMap, setOrderEntryToOrderMap] = useState<
    Map<string, OrderDeepFragment>
  >(new Map());

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function resolve() {
      setIsLoading(true);
      if (isAllPropertiesLoaded) {
        const resolvedOrdersResult = new Map<
          string,
          ElementIdentifierDbidType[]
        >();
        const orderEntryToOrderResult = new Map<string, OrderDeepFragment>();
        const resolvedOrderEntriesResult = new Map<
          string,
          ElementIdentifierDbidType[]
        >();
        const orderMapResult = new Map<string, OrderDeepFragment>();
        const orderEntryMapResult = new Map<string, OrderEntryDeepFragment>();

        for (const order of orders) {
          let resolvedOrderResult: ElementIdentifierDbidType[] = [];
          for (const entry of order.orderEntries) {
            const resolvedDbIds =
              (await resolveDbIds(
                propertyContext,
                entry.elementIdentifiersQuery,
                entry.elementIdentifiersDbid
              )) ?? [];
            resolvedOrderEntriesResult.set(entry.id, resolvedDbIds);
            resolvedOrderResult = merge(resolvedOrderResult, resolvedDbIds);
            orderEntryToOrderResult.set(entry.id, order);
            orderEntryMapResult.set(entry.id, entry);
          }
          resolvedOrdersResult.set(order.id, resolvedOrderResult);
          orderMapResult.set(order.id, order);
        }
        setOrderEntryMap(orderEntryMapResult);
        setOrderMap(orderMapResult);
        setOrderEntryToOrderMap(orderEntryToOrderResult);
        setResolvedOrderEntries(resolvedOrderEntriesResult);
        setResolvedOrders(resolvedOrdersResult);
        setIsLoading(false);
      }
    }
    resolve();
  }, [isAllPropertiesLoaded, orders, propertyContext]);

  const findOrdersMatchingDbIds = useCallback(
    (dbIds: ElementIdentifierDbidType[]) => {
      const result: OrderDeepFragment[] = [];
      resolvedOrders.forEach((resolvedDbIds, orderId) => {
        if (intersects(dbIds, resolvedDbIds)) {
          const order = orderMap.get(orderId);
          invariant(order, 'Order not found in map. Bad code');
          result.push(order);
        }
      });
      return result;
    },
    [orderMap, resolvedOrders]
  );

  const findOrderEntriesMatchingDbIds = useCallback(
    (dbIds: ElementIdentifierDbidType[]) => {
      const result: OrderEntryWithOrder[] = [];
      resolvedOrderEntries.forEach((resolvedDbIds, orderEntryId) => {
        if (intersects(dbIds, resolvedDbIds)) {
          const orderEntry = orderEntryMap.get(orderEntryId);
          invariant(orderEntry, 'Order entry not found in map. Bad code');
          const order = orderEntryToOrderMap.get(orderEntryId);
          invariant(order, 'Order entry missing order. Bad code');
          result.push({
            orderEntry,
            order,
          });
        }
      });
      return result;
    },
    [orderEntryMap, orderEntryToOrderMap, resolvedOrderEntries]
  );

  const getDbIdsForOrder = useCallback(
    (orderId: string) => {
      const dbIds = resolvedOrders.get(orderId);
      if (!dbIds) {
        throw 'Order not found in map, call useResolvedOrders with the correct orders';
      }
      return dbIds;
    },
    [resolvedOrders]
  );

  return useMemo(
    () => ({
      isLoading,
      error,
      findOrdersMatchingDbIds,
      findOrderEntriesMatchingDbIds,
      getDbIdsForOrder,
    }),
    [
      isLoading,
      error,
      findOrdersMatchingDbIds,
      findOrderEntriesMatchingDbIds,
      getDbIdsForOrder,
    ]
  );
}

const resolveOrder = async (
  client: Client,
  order: OrderDeepFragment,
  context: PropertyContext,
  persist = true,
  unitSettings?: UnitSettingsType
): Promise<ResolvedOrder> => {
  const resolving = order.orderEntries.map(async (entry) =>
    resolveOrderEntry(
      order.projectId,
      client,
      context,
      order.orderColumns,
      entry,
      persist,
      unitSettings
    )
  );
  const resolvedEntries = await Promise.all(resolving);
  return {
    ...order,
    labels: parseLabels(order.labels) as ResolvedOrder['labels'],
    orderEntries: sortBy(resolvedEntries, [
      (entry) => entry.createdAt,
      (entry) => entry.name,
    ]),
  };
};

const resolveOrderBatch = async (
  saveOrder: ResolvedOrderUpdater,
  order: OrderDeepFragment,
  context: PropertyContext,
  persist: boolean,
  unitSettings?: UnitSettingsType
): Promise<ResolvedOrder> => {
  const resolvedEntries = await resolveOrderEntryBatch(
    saveOrder,
    order.projectId,
    context,
    order.orderColumns,
    order.orderEntries,
    persist,
    unitSettings
  );

  return {
    ...order,
    labels: parseLabels(order.labels) as ResolvedOrder['labels'],
    orderEntries: sortBy(resolvedEntries, [
      (entry) => entry.createdAt,
      (entry) => entry.name,
    ]),
  };
};

export type UseOrderReturn = {
  order: OrderDeepFragment | null;
  resolvedOrder: ResolvedOrder | null;
  resolving: boolean;
  loading: boolean;
  error: object | undefined;
};

type ResolvedOrderUpdater = {
  (
    entriesToUpdate: OrderEntryOnOrderEntryForOrderEntriesOrderIdFkeyUsingOrderEntriesPkeyUpdate[]
  ): Promise<OrderDeepFragment>;
};

export const useResolvedOrder = (
  projectId: string,
  orderId: string
): UseOrderReturn => {
  const [resolving, setResolving] = useState(false);
  // TODO: jonasws: 2022-03-03 Add a loading spinner here?
  const [resolvedOrder, setResolvedOrder] = useState<ResolvedOrder | null>(
    null
  );
  const user = useUserTenant();

  const [{ data: orderData, fetching, error: orderError }] = useQuery({
    query: GetOrderDeepDocument,
    variables: {
      id: orderId,
    },
  });

  const [{ data: projectSettingsData }] = useQuery({
    query: GetProjectSettingsDocument,
    variables: {
      projectId,
    },
  });

  const unitSettings = projectSettingsData?.project?.settings
    ?.unitSettings as UnitSettingsType;

  const saveOrder: ResolvedOrderUpdater = useCallback(
    async (
      entriesToUpdate: OrderEntryOnOrderEntryForOrderEntriesOrderIdFkeyUsingOrderEntriesPkeyUpdate[]
    ) => {
      invariant(entriesToUpdate.length > 0, 'No entries to update');

      const session = await Auth.currentSession();
      const authToken = session.getIdToken().getJwtToken();

      const input: UpdateOrderInput = {
        id: orderId,
        patch: {
          orderEntries: {
            updateById: entriesToUpdate,
          },
        },
      };
      let graphqlEndpoint: string;
      if (process.env.NODE_ENV === 'development') {
        graphqlEndpoint = '/graphql?operationName=UpdateOrder';
      } else {
        graphqlEndpoint = '/graphql';
      }
      // We're using fetch instead of urql here to avoid the cache being updated, which would cause re-rendering we don't want
      const response = await customFetch(graphqlEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Encoding': 'gzip, deflate, br',
          Accept: 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          query: print(UpdateOrderDocument),
          variables: {
            input,
          },
        }),
      }).then((res) => res.json());

      return response.data.updateOrder.order;
    },
    [orderId]
  );

  const { propertyContext, isAllPropertiesLoaded, error } =
    usePropertyContext(projectId);

  if (error) {
    throw 'Error fetching properties';
  }

  useEffect(() => {
    const resolve = async () => {
      if (orderData?.order && isAllPropertiesLoaded) {
        if (orderData.order.isAggregatedOrder) {
          setResolvedOrder({
            ...orderData.order,
            labels: parseLabels(
              orderData.order.labels
            ) as ResolvedOrder['labels'],
            orderEntries: [],
          });
          return;
        }
        // Get whether to resolve the order and the corresponding cache otherwise
        const ordersToResolve = getOrdersToResolve(projectId, propertyContext, [
          orderData.order,
        ]);
        const cachedOrder = ordersCache.cachedResolvedOrders.get(
          orderData.order.id
        );
        if (ordersToResolve.length <= 0 && cachedOrder) {
          setResolvedOrder(cachedOrder);
          return;
        }
        setResolving(true);

        try {
          const resolvedOrder = await resolveOrderBatch(
            saveOrder,
            orderData.order,
            propertyContext,
            user?.tenant?.group === 'Protan' ? false : true,
            unitSettings
          );
          setResolvedOrder(resolvedOrder);
          // Set the cache for future lookups.
          ordersCache.cachedOrders.set(orderData.order.id, orderData.order);
          ordersCache.cachedResolvedOrders.set(resolvedOrder.id, resolvedOrder);
          ordersCache.propertyContext = propertyContext;
          ordersCache.projectId = projectId;
        } catch (e) {
          console.error('An error occured during resolving', e);
          throw e;
        } finally {
          setResolving(false);
        }
      }
    };

    resolve();
  }, [
    propertyContext,
    orderData?.order,
    isAllPropertiesLoaded,
    saveOrder,
    user?.tenant?.group,
    unitSettings,
  ]);

  return {
    order: orderData?.order ?? null,
    resolvedOrder: resolvedOrder,
    loading: fetching,
    // This is to only make it true when we're "updating" an already resolved order, and not on the "first page load"
    resolving: resolving && resolvedOrder !== null,
    error: orderError,
  };
};

export function useOrderStatusColor() {
  const [gray100, openColor, inProgressColor, completedColor] = useToken(
    'colors',
    [
      'gray.100',
      orderStatusColorMapper(OrderStatus.Open),
      orderStatusColorMapper(OrderStatus.InProgress),
      orderStatusColorMapper(OrderStatus.Completed),
    ]
  );

  function orderStatusHexMapper(orderStatus: OrderStatus) {
    switch (orderStatus) {
      case OrderStatus.Open:
        return openColor;
      case OrderStatus.InProgress:
        return inProgressColor;
      case OrderStatus.Completed:
        return completedColor;
      default:
        return gray100;
    }
  }

  return { orderStatusHexMapper };
}

export function orderStatusColorMapper(orderStatus: OrderStatus): string {
  switch (orderStatus) {
    case OrderStatus.Open:
      return 'orange.200';
    case OrderStatus.InProgress:
      return 'cyan.400';
    case OrderStatus.Completed:
      return 'green.300';
    default:
      return 'gray.100';
  }
}

export function orderStatusLabelMapper(orderStatus: OrderStatus): string {
  switch (orderStatus) {
    case OrderStatus.InProgress:
      return 'In progress';
    default:
      return orderStatus[0].toUpperCase() + orderStatus.slice(1).toLowerCase();
  }
}
