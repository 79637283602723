import {
  Box,
  Button,
  Divider,
  Flex,
  GridItem,
  Heading,
  Spinner,
  Text,
} from '@chakra-ui/react';
import {
  faClone,
  faList,
  faMagnifyingGlass,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { useFlag } from '@unleash/proxy-client-react';
import { capitalize } from 'lodash';
import React, { Suspense } from 'react';
import {
  Outlet,
  NavLink as RouterLink,
  useLocation,
  useMatches,
} from 'react-router-dom';

import SparkelIcon, {
  type IconProp,
} from '../components/common/icon/SparkelIcon';
import { TutorialsBanner } from '../components/company/projectsPage/TutorialsBanner';
import { Layout, Sidebar } from '../components/layouts/layouts';
import { useDocumentTitle } from '../hooks/document';
import { useLocalStorage } from '../hooks/local-storage';
import { useUserTenant } from '../services/auth-info';

type UserTenant = ReturnType<typeof useUserTenant>;

type NavigationMenuItem = {
  url: string;
  label: string;
  icon: IconProp;
  when?: (userTenant: UserTenant) => boolean;
};

type NavigationMenuProps = {
  items: NavigationMenuItem[];
};

const NavigationMenu = ({ items }: NavigationMenuProps): React.ReactElement => {
  const { pathname } = useLocation();
  const user = useUserTenant();

  return (
    <Flex
      as="nav"
      flexGrow={1}
      direction="column"
      alignItems="stretch"
      gap={2}
      paddingY={4}
      paddingX={2}
    >
      {items.map(({ url, label, icon, when }) => {
        let shouldShow = true;

        if (typeof when === 'function') {
          shouldShow = when(user);
        }

        if (!shouldShow) {
          return null;
        } else {
          return (
            <Button
              to={url}
              key={label}
              variant="ghost"
              colorScheme={url === pathname ? 'blue' : 'gray'}
              borderRadius={'md'}
              size="sm"
              isActive={url === pathname}
              justifyContent="flex-start"
              as={RouterLink}
              fontWeight={url === pathname ? 'bold' : 'normal'}
              leftIcon={<SparkelIcon icon={icon} color="inherit" />}
            >
              {label}
            </Button>
          );
        }
      })}
    </Flex>
  );
};

type CompanyRouteHandle = {
  title: string;
};

const Company = () => {
  const matches = useMatches();
  const enableGraphiql = useFlag('enable-graphiql');
  const userTenant = useUserTenant();
  const tenant = userTenant.tenant?.name;

  const handle = matches.at(-1)?.handle as CompanyRouteHandle | undefined;
  const title = handle?.title ?? 'Sparkel';

  useDocumentTitle(capitalize(title));

  const [shouldShowTutorials, setShouldShowTutorials] = useLocalStorage<
    string[]
  >('show-tutorials-banner', []);

  const menuItems: NavigationMenuItem[] = [
    {
      label: 'Projects',
      url: '/projects',
      icon: faList,
    },
    {
      label: 'Templates',
      url: '/templates',
      icon: faClone,
    },
    {
      label: 'Users',
      url: '/users',
      icon: faUsers, // Use something else here
      when: (user) => user.isAdmin,
    },
  ];

  if (enableGraphiql) {
    menuItems.push({
      label: 'GraphQL Playground',
      url: '/graphiql',
      icon: faMagnifyingGlass,
    });
  }

  return (
    <Layout>
      <Sidebar positioning="left" spacing={0} width="264px" paddingLeft={2}>
        <Box style={{ textAlign: 'center', paddingTop: 48 }}>
          <Box
            display={'inline-block'}
            height={'96px'}
            width={'96px'}
            borderRadius="full"
            backgroundColor={'cyan.500'}
            _dark={{
              backgroundColor: 'cyan.200',
            }}
          >
            <Text
              lineHeight="96px"
              fontSize="4xl"
              color={'white'}
              _dark={{ color: 'gray.900' }}
            >
              {tenant ? tenant[0] + tenant[1] : ''}
            </Text>
          </Box>
          <Heading as="h3" size="sm" padding={6}>
            {tenant ? tenant : ''}
          </Heading>
        </Box>
        <Divider />

        <NavigationMenu items={menuItems} />
      </Sidebar>
      <GridItem
        area="sidebar-left-start / sidebar-left-end / sidebar-right-end / sidebar-right-end"
        display="flex"
        flexDirection="column"
        paddingTop={4}
        paddingRight={4}
      >
        {shouldShowTutorials.length === 0 ||
        (shouldShowTutorials.length > 0 &&
          shouldShowTutorials[0] === 'true') ? (
          <TutorialsBanner setShouldShowTutorials={setShouldShowTutorials} />
        ) : undefined}
        <Heading as="h4" size="xl" fontWeight="normal" my={4}>
          {title}
        </Heading>
        <Suspense fallback={<Spinner />}>
          <Outlet />
        </Suspense>
      </GridItem>
    </Layout>
  );
};

export default Company;
