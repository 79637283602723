import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LineSegment2,
  MultiPolygon2,
  Point2,
} from '../../../domain/geometry/geometric-types';
import { useSheetViewer } from '../../common/SheetViewer';
import {
  ProtanProjectStepEnum,
  ProtanProjectStepStatusEnum,
} from '../../../gql/graphql';
import { useProtanProjectSteps } from '../../common/ProtanProjectStepsProvider';
import { useUserTenant } from '../../../services/auth-info';
import { SheetEdge } from './SheetEdge';
import { SheetPolygon } from './SheetPolygon';
import {
  InitialShapeDrawingState,
  PolygonShapeDrawingResult,
  ShapeDrawingMode,
} from './SheetShapeDrawing';

import { DraggableVertex, SheetVertex } from './SheetVertex';
import AutoGeneratedRoof from './AutoGeneratedRoof';

const getPoints = (
  startPoint: Point2 | null,
  endPoint: Point2 | null
): Point2[] => {
  if (startPoint && endPoint) {
    return [
      startPoint,
      [endPoint[0], startPoint[1]],
      endPoint,
      [startPoint[0], endPoint[1]],
    ];
  }
  return [];
};

const getMultipolygon = (points: Point2[]): MultiPolygon2 | null => {
  if (points.length === 4) {
    return {
      polygons: [
        {
          exterior: [points[0], points[1], points[2], points[3], points[0]],
          interiors: [],
        },
      ],
    };
  }
  return null;
};

// eslint-disable-next-line complexity
export const SheetRectangleShapeDrawing = ({
  initialState,
  onResult,
  getPointInPdfCoordinateSystem,
  getPointInDomCoordinateSystem,
}: {
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.Rectangle];
  onResult: (result: PolygonShapeDrawingResult) => void;
  getPointInPdfCoordinateSystem: (pointInDom: Point2) => Point2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
}) => {
  const [startPoint, setStartPoint] = useState<Point2 | null>(
    initialState?.startPoint ?? null
  );
  const [endPoint, setEndPoint] = useState<Point2 | null>(
    initialState?.endPoint ?? null
  );
  const [intermediateEndpoint, setIntermediateEndpoint] =
    useState<Point2 | null>(null);

  const derivedPoint1: Point2 | null = useMemo(() => {
    if (startPoint && endPoint) {
      return [endPoint[0], startPoint[1]];
    } else if (startPoint && intermediateEndpoint) {
      return [intermediateEndpoint[0], startPoint[1]];
    }
    return null;
  }, [startPoint, endPoint, intermediateEndpoint]);

  const derivedPoint2: Point2 | null = useMemo(() => {
    if (startPoint && endPoint) {
      return [startPoint[0], endPoint[1]];
    } else if (startPoint && intermediateEndpoint) {
      return [startPoint[0], intermediateEndpoint[1]];
    }
    return null;
  }, [startPoint, endPoint, intermediateEndpoint]);

  const points: Point2[] = useMemo(() => {
    return getPoints(startPoint, endPoint ?? intermediateEndpoint);
  }, [startPoint, endPoint, intermediateEndpoint]);

  const edges = useMemo(() => {
    const edges: LineSegment2[] = [];
    for (let i = 0; i < points.length - 1; i++) {
      edges.push([points[i], points[i + 1]]);
    }
    if (points.length >= 2) {
      edges.push([points[points.length - 1], points[0]]);
    }
    return edges;
  }, [points]);

  const emitResult = useCallback(
    (startPoint: Point2, endPoint: Point2) => {
      const points = getPoints(startPoint, endPoint);
      const multipolygon = getMultipolygon(points);
      if (multipolygon) {
        onResult({ multipolygon, valid: true });
      } else {
        onResult({ valid: false });
      }
    },
    [onResult]
  );

  const getPoint = useCallback(
    (event: MouseEvent): Point2 => {
      return getPointInPdfCoordinateSystem([event.clientX, event.clientY]);
    },
    [getPointInPdfCoordinateSystem]
  );

  const handleViewerClick = useCallback(
    (event: MouseEvent) => {
      if (event.button !== 0 || endPoint) {
        return;
      }

      const newPoint = getPoint(event);
      if (!startPoint) {
        setStartPoint(newPoint);
      } else {
        setEndPoint(newPoint);
        emitResult(startPoint, newPoint);
      }
    },
    [emitResult, endPoint, getPoint, startPoint]
  );

  const { viewerRef } = useSheetViewer();
  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('click', handleViewerClick);
    return () => {
      current.removeEventListener('click', handleViewerClick);
    };
  }, [handleViewerClick, viewerRef]);

  const handlePointerMove = useCallback(
    (event: PointerEvent) => {
      if (!startPoint || endPoint) {
        return;
      }

      const newPoint = getPoint(event);
      setIntermediateEndpoint(newPoint);
    },
    [endPoint, getPoint, startPoint]
  );

  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('pointermove', handlePointerMove);
    return () => {
      current.removeEventListener('pointermove', handlePointerMove);
    };
  }, [handlePointerMove, viewerRef]);

  const handleStartVertexDrag = (event: MouseEvent) => {
    const newPoint = getPoint(event);
    setStartPoint(newPoint);

    if (event.type === 'pointerup' && endPoint) {
      emitResult(newPoint, endPoint);
    }
  };

  const handleEndVertexDrag = (event: MouseEvent) => {
    const newPoint = getPoint(event);
    setEndPoint(newPoint);

    if (event.type === 'pointerup' && startPoint) {
      emitResult(startPoint, newPoint);
    }
  };

  const multipolygon = useMemo(() => {
    return getMultipolygon(points);
  }, [points]);

  //Hook her for å bestemme autogenerering
  const { projectSteps } = useProtanProjectSteps();
  const { tenant } = useUserTenant();
  const roofOutlineInProgress =
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.RoofOutline
    )?.status === ProtanProjectStepStatusEnum.InProgress;
  return (
    <>
      {multipolygon ? (
        <SheetPolygon
          multipolygon={multipolygon}
          fill={endPoint ? 'green.400' : 'orange.500'}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        />
      ) : null}
      {tenant?.group === 'Protan' &&
        multipolygon &&
        endPoint &&
        roofOutlineInProgress && (
          <AutoGeneratedRoof
            vertices={multipolygon.polygons[0].exterior}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          ></AutoGeneratedRoof>
        )}
      {edges.map((edge, index) => {
        const color = endPoint != null ? 'green.500' : 'yellow.300';
        // todo: index is not a good key. use a unique id
        return (
          <SheetEdge
            key={index}
            segment={edge}
            backgroundColor={color}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            pointerEvents={endPoint ? undefined : 'none'} // Prevent edges on mouse pointer from capturing mouse events
          />
        );
      })}
      {derivedPoint1 ? (
        <SheetVertex
          point={derivedPoint1}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        />
      ) : null}
      {derivedPoint2 ? (
        <SheetVertex
          point={derivedPoint2}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        />
      ) : null}
      {startPoint ? (
        <DraggableVertex
          theme="inverted"
          point={startPoint}
          onVertexDrag={handleStartVertexDrag}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        />
      ) : null}
      {endPoint ? (
        <DraggableVertex
          theme="inverted"
          point={endPoint}
          onVertexDrag={handleEndVertexDrag}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        />
      ) : null}
    </>
  );
};
