import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
  Text,
  Box,
  Stack,
  Wrap,
  WrapItem,
  Badge,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';

type ConfirmDeletionModalProps = {
  title?: string;
  isOpen: boolean;
  onConfirm: () => Promise<void>;
  onClose: () => void;
  itemsToDelete?: string[];
  children?: React.ReactNode;
};

export default function ConfirmDeletionModal({
  title,
  isOpen,
  onConfirm,
  onClose,
  itemsToDelete,
  children,
}: ConfirmDeletionModalProps): React.ReactElement {
  const [deletionInProgress, setDeletionInProgress] = useState<boolean>(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();

  const handleConfirmDeletion = async () => {
    setDeletionInProgress(true);

    try {
      await onConfirm();
      onClose();
      setDeletionInProgress(false);
    } catch (err) {
      toast({
        title: 'An error occurred when deleting, error message: ' + err,
        status: 'error',
      });
      setDeletionInProgress(false);
      console.error('An error occurred when deleting', err);
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title ?? 'Confirm deletion'}
          </AlertDialogHeader>
          <AlertDialogBody>
            <Stack columnGap={2}>
              {children ? (
                <Box>{children}</Box>
              ) : (
                <>
                  {itemsToDelete && itemsToDelete.length > 0 && (
                    <Wrap>
                      {itemsToDelete.map((item) => (
                        <WrapItem key={item}>
                          <Badge size="md" px={2}>
                            {item}
                          </Badge>
                        </WrapItem>
                      ))}
                    </Wrap>
                  )}
                  <Text>
                    Are you sure you want to delete? You can not undo this
                    action afterwards.
                  </Text>
                </>
              )}
            </Stack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme={'gray'} ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              isLoading={deletionInProgress}
              onClick={handleConfirmDeletion}
              ml={3}
              variant={'outline'}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
