import * as math from 'mathjs';
import {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { isHotkeyPressed, useHotkeys } from 'react-hotkeys-hook';
import invariant from 'tiny-invariant';
import {
  LineSegment2,
  MultiPolygon2,
  Point2,
} from '../../../domain/geometry/geometric-types';
import {
  addEdge,
  copy,
  getInvalidEdges,
  intersects,
} from '../../../services/viewer/shape-drawing/intersections';
import { useSheetViewer } from '../../common/SheetViewer';
import {
  Point2WithId,
  createIntermediateEdge,
  createMultipolygon,
  generateIdForRing,
  generateIdsForEdges,
  generateIdsForPoints,
  getArcPoints,
  getPositions,
  intermediateEdgeId,
} from '../shapes/util';
import {
  ProtanProjectStepEnum,
  ProtanProjectStepStatusEnum,
} from '../../../gql/graphql';
import { useProtanProjectSteps } from '../../common/ProtanProjectStepsProvider';
import { useUserTenant } from '../../../services/auth-info';
import { DraggableSheetEdge, SheetEdge } from './SheetEdge';
import {
  InitialShapeDrawingState,
  PolygonShapeDrawingResult,
  ShapeDrawingMode,
} from './SheetShapeDrawing';

import { SheetPolygon } from './SheetPolygon';
import { DraggableVertex } from './SheetVertex';
import {
  SheetPolygonDrawingAction,
  SheetPolygonDrawingActionType,
  SheetPolygonDrawingState,
  sheetPolygonDrawingReducer,
} from './sheet-polygon-drawing-reducer';
import AutoGeneratedRoof from './AutoGeneratedRoof';

function initializeState(
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.Polygon]
): SheetPolygonDrawingState {
  if (!initialState) {
    return {
      completedRings: [],
      incompleteRing: [],
      mousePointerPoint: null,
      intersectionsForIncompleteRing: new Map(),
      intersectionsForCompletedRings: new Map(),
    };
  }
  return {
    completedRings: initialState.multipolygon.polygons.flatMap((polygon) => [
      generateIdForRing(generateIdsForPoints(polygon.exterior)),
      ...polygon.interiors.map((interior) =>
        generateIdForRing(generateIdsForPoints(interior))
      ),
    ]),
    incompleteRing: [],
    mousePointerPoint: null,
    intersectionsForIncompleteRing: new Map(),
    intersectionsForCompletedRings: new Map(),
  };
}
export const SheetPolygonShapeDrawing = ({
  initialState,
  onResult,
  getPointInPdfCoordinateSystem,
  getPointInDomCoordinateSystem,
}: {
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.Polygon];
  onResult: (result: PolygonShapeDrawingResult) => void;
  getPointInPdfCoordinateSystem: (pointInDom: Point2) => Point2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
}) => {
  const [state, dispatch] = useReducer(
    sheetPolygonDrawingReducer,
    initialState,
    initializeState
  );

  const [isDragging, setIsDragging] = useState(false);

  const isShiftPressed = isHotkeyPressed('shift');

  const emitResult = useCallback(() => {
    const hasNoIntersections = [
      ...state.intersectionsForCompletedRings.values(),
    ].every((intersections) => intersections.length === 0);
    const hasNoNewPoints = state.incompleteRing.length === 0;
    const hasConfirmedRing = state.completedRings.length > 0;

    const isValidResult =
      hasNoIntersections && hasNoNewPoints && hasConfirmedRing;

    if (isValidResult && !isDragging) {
      const ringPositions = state.completedRings.map((ring) =>
        getPositions(ring.linearRing)
      );
      onResult({
        valid: true,
        multipolygon: createMultipolygon(ringPositions),
      });
    } else {
      onResult({
        valid: false,
      });
    }
  }, [
    isDragging,
    onResult,
    state.completedRings,
    state.incompleteRing.length,
    state.intersectionsForCompletedRings,
  ]);

  useEffect(() => {
    if (!isDragging) {
      emitResult();
    }
  }, [emitResult, isDragging]);

  const getPoint = useCallback(
    (event: MouseEvent): Point2 => {
      if (isShiftPressed && state.incompleteRing.length > 0) {
        const previousPointInDomCoordinates = getPointInDomCoordinateSystem(
          state.incompleteRing[state.incompleteRing.length - 1].point
        );
        const deltaX = event.clientX - previousPointInDomCoordinates[0];
        const deltaY = event.clientY - previousPointInDomCoordinates[1];
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          return getPointInPdfCoordinateSystem([
            event.clientX,
            previousPointInDomCoordinates[1],
          ]);
        } else {
          return getPointInPdfCoordinateSystem([
            previousPointInDomCoordinates[0],
            event.clientY,
          ]);
        }
      }
      return getPointInPdfCoordinateSystem([event.clientX, event.clientY]);
    },
    [
      getPointInDomCoordinateSystem,
      getPointInPdfCoordinateSystem,
      isShiftPressed,
      state.incompleteRing,
    ]
  );

  return (
    <>
      <CompletedRings
        state={state}
        dispatch={dispatch}
        getPoint={getPoint}
        setIsDragging={setIsDragging}
        getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        getPointInPdfCoordinateSystem={getPointInPdfCoordinateSystem}
      />
      <IncompleteRing
        state={state}
        dispatch={dispatch}
        getPoint={getPoint}
        isDragging={isDragging}
        setIsDragging={setIsDragging}
        getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        getPointInPdfCoordinateSystem={getPointInPdfCoordinateSystem}
      />
    </>
  );
};

function IncompleteRing({
  state,
  dispatch,
  getPoint,
  isDragging,
  setIsDragging,
  getPointInDomCoordinateSystem,
  getPointInPdfCoordinateSystem,
}: {
  state: SheetPolygonDrawingState;
  dispatch: Dispatch<SheetPolygonDrawingAction>;
  getPoint: (event: MouseEvent) => Point2;
  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => void;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
  getPointInPdfCoordinateSystem: (pointInDom: Point2) => Point2;
}) {
  // To avoid triggering click events when double clicking
  const clickTimeoutRef = useRef<number>();
  const edgesForIncompleteRing = useMemo(
    () => generateIdsForEdges(state.incompleteRing),
    [state.incompleteRing]
  );

  const shouldAddMousePointerEdge =
    !!state.mousePointerPoint && state.incompleteRing.length > 0;

  const intersectionsForIncompleteRingIncludingMouseEdge = useMemo(() => {
    let result = state.intersectionsForIncompleteRing;
    if (state.mousePointerPoint && shouldAddMousePointerEdge) {
      // Find intersections with the edge between the last confirmed point and the mouse edge
      result = copy(result);
      const intermediateEdge = createIntermediateEdge(
        state.incompleteRing,
        state.mousePointerPoint
      );
      addEdge(result, edgesForIncompleteRing, intermediateEdge);
    }
    return result;
  }, [
    edgesForIncompleteRing,
    shouldAddMousePointerEdge,
    state.incompleteRing,
    state.intersectionsForIncompleteRing,
    state.mousePointerPoint,
  ]);

  const invalidEdgesForIncompleteRing = useMemo(() => {
    return getInvalidEdges(
      edgesForIncompleteRing,
      intersectionsForIncompleteRingIncludingMouseEdge
    );
  }, [
    edgesForIncompleteRing,
    intersectionsForIncompleteRingIncludingMouseEdge,
  ]);

  const doesIncompleteRingFormAValidPolygon = useMemo(() => {
    const numPoints = state.mousePointerPoint
      ? state.incompleteRing.length + 1
      : state.incompleteRing.length;

    if (numPoints < 3) {
      return false;
    }
    if (invalidEdgesForIncompleteRing.length > 0) {
      return false;
    }
    if (state.mousePointerPoint) {
      // check intersection from the mouse pointer point to the first point
      const edgeFromMousePoint: LineSegment2 = [
        state.mousePointerPoint,
        state.incompleteRing[0].point,
      ];
      return !edgesForIncompleteRing.some((edge) =>
        intersects(edgeFromMousePoint, edge.segment)
      );
    } else {
      // check intersection from the last point to the first point
      const edgeFromLastPoint: LineSegment2 = [
        state.incompleteRing[state.incompleteRing.length - 1].point,
        state.incompleteRing[0].point,
      ];
      return !edgesForIncompleteRing.some((edge) =>
        intersects(edgeFromLastPoint, edge.segment)
      );
    }
  }, [
    edgesForIncompleteRing,
    invalidEdgesForIncompleteRing.length,
    state.incompleteRing,
    state.mousePointerPoint,
  ]);

  const isAltKeyPressed = isHotkeyPressed('alt');

  const handleViewerClick = useCallback(
    (event: MouseEvent) => {
      if (event.button !== 0) {
        return;
      }
      const newPoint = getPoint(event);
      dispatch({
        type: SheetPolygonDrawingActionType.ADD_INCOMPLETE_RING_POINT,
        position: newPoint,
        isAltKeyPressed: isAltKeyPressed && state.incompleteRing.length > 1,
      });
      event.stopPropagation();
    },
    [dispatch, getPoint, isAltKeyPressed, state.incompleteRing.length]
  );
  const { viewerRef } = useSheetViewer();
  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('click', handleViewerClick);
    return () => {
      current.removeEventListener('click', handleViewerClick);
    };
  }, [handleViewerClick, viewerRef]);

  const handlePointerMove = useCallback(
    (event: PointerEvent) => {
      if (state.incompleteRing.length === 0 || isDragging) {
        return;
      }
      const newPoint = getPoint(event);
      dispatch({
        type: SheetPolygonDrawingActionType.SET_MOUSE_POINTER_POINT,
        point: newPoint,
      });
    },
    [dispatch, getPoint, isDragging, state.incompleteRing.length]
  );

  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('pointermove', handlePointerMove);
    return () => {
      current.removeEventListener('pointermove', handlePointerMove);
    };
  }, [handlePointerMove, viewerRef]);

  const handleVertexDrag = (event: MouseEvent, pointId: string) => {
    setIsDragging(true);
    const position = getPoint(event);
    dispatch({
      type: SheetPolygonDrawingActionType.MOVE_INCOMPLETE_RING_POINT,
      position,
      pointId,
    });
    if (event.type === 'pointerup') {
      setIsDragging(false);
    }
  };

  const handleEdgeDrag = (event: React.MouseEvent, edgeId: string) => {
    setIsDragging(true);
    const startVector = getPointInPdfCoordinateSystem([
      event.clientX - event.movementX,
      event.clientY - event.movementY,
    ]);
    const translationVector = math.subtract(
      getPointInPdfCoordinateSystem([event.clientX, event.clientY]),
      startVector
    );
    const edgeIndex = edgesForIncompleteRing.findIndex(
      (edge) => edge.id === edgeId
    );
    const startPointId = state.incompleteRing[edgeIndex].id;

    dispatch({
      type: SheetPolygonDrawingActionType.MOVE_INCOMPLETE_RING_EDGE,
      startPositionTranslation: translationVector,
      endPositionTranslation: translationVector,
      startPointId,
    });

    if (event.type === 'pointerup') {
      setIsDragging(false);
    }
  };
  const handleFirstVertexClick = (event: React.MouseEvent) => {
    const handleFirstVertexClickThrottled = () => {
      if (
        state.incompleteRing.length < 3 ||
        event.button !== 0 ||
        event.detail > 1
      ) {
        return;
      }
      dispatch({ type: SheetPolygonDrawingActionType.CLOSE_RING });
    };
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current); // Clear any existing timeout
    }
    // Wait before executing single click action
    const newTimeout = window.setTimeout(handleFirstVertexClickThrottled, 300);

    clickTimeoutRef.current = newTimeout;
  };

  const handleVertexDoubleClick = (
    event: React.MouseEvent,
    pointId: string
  ) => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current); // Clear the single click timeout
    }
    if (event.button !== 0) {
      return;
    }
    dispatch({
      type: SheetPolygonDrawingActionType.REMOVE_INCOMPLETE_RING_POINT,
      pointId,
    });
  };

  const handleEdgeDoubleClick = (event: React.MouseEvent, edgeId: string) => {
    if (event.button !== 0) {
      return;
    }
    const newPoint = getPointInPdfCoordinateSystem([
      event.clientX,
      event.clientY,
    ]);
    const edgeIndex = edgesForIncompleteRing.findIndex(
      (edge) => edge.id === edgeId
    );
    const startPointId = state.incompleteRing[edgeIndex].id;
    dispatch({
      type: SheetPolygonDrawingActionType.SPLIT_INCOMPLETE_RING_EDGE,
      splitPosition: newPoint,
      startPointId,
    });
  };

  useHotkeys(
    ['enter'],
    () => dispatch({ type: SheetPolygonDrawingActionType.CLOSE_RING }),
    {
      preventDefault: true,
    }
  );

  const isIntermediateEdgeValid = useMemo(() => {
    const intersectionsForEdge =
      intersectionsForIncompleteRingIncludingMouseEdge.get(intermediateEdgeId);
    if (!intersectionsForEdge) {
      return true;
    }
    return intersectionsForEdge.length === 0;
  }, [intersectionsForIncompleteRingIncludingMouseEdge]);

  const incompletePolygon: MultiPolygon2 | null = useMemo(() => {
    if (!doesIncompleteRingFormAValidPolygon) {
      return null;
    }
    const exterior = getPositions(state.incompleteRing);
    if (shouldAddMousePointerEdge && state.mousePointerPoint) {
      exterior.push(state.mousePointerPoint);
    }
    exterior.push(state.incompleteRing[0].point);
    return {
      polygons: [
        {
          exterior,
          interiors: [],
        },
      ],
    };
  }, [
    doesIncompleteRingFormAValidPolygon,
    shouldAddMousePointerEdge,
    state.incompleteRing,
    state.mousePointerPoint,
  ]);
  return (
    <>
      {incompletePolygon && !isAltKeyPressed && (
        <SheetPolygon
          multipolygon={incompletePolygon}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          fill={'orange.500'}
        />
      )}
      {edgesForIncompleteRing.map(({ id, segment }, idx) => {
        const color = getEdgeColor(
          false,
          !invalidEdgesForIncompleteRing.includes(id)
        );
        if (isAltKeyPressed && idx === edgesForIncompleteRing.length - 1)
          return;
        return (
          <DraggableSheetEdge
            key={id}
            onDoubleClick={(event) => handleEdgeDoubleClick(event, id)}
            segment={segment}
            backgroundColor={color}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            onEdgeDrag={(event) => handleEdgeDrag(event, id)}
          />
        );
      })}
      {shouldAddMousePointerEdge
        ? IntermediateEdges(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            state.mousePointerPoint!,
            state.incompleteRing,
            isAltKeyPressed && state.incompleteRing.length > 1,
            getEdgeColor(false, isIntermediateEdgeValid),
            getPointInDomCoordinateSystem
          )
        : null}
      {state.incompleteRing.map(({ point, id: pointId }, index) => {
        return (
          <DraggableVertex
            key={pointId}
            theme={index === 0 ? 'inverted' : 'default'}
            point={point}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            onClick={
              index === 0 ? (event) => handleFirstVertexClick(event) : undefined
            }
            onDoubleClick={(event) => handleVertexDoubleClick(event, pointId)}
            onVertexDrag={(event) => handleVertexDrag(event, pointId)}
          />
        );
      })}
    </>
  );
}

export const IntermediateEdges = (
  intermediatePoint: Point2,
  ring: Point2WithId[],
  isArc: boolean,
  color: string,
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2
) => {
  if (isArc) {
    const startPoint = ring[ring.length - 2].point;
    const endPoint = ring[ring.length - 1].point;

    const arcPoints = getArcPoints(startPoint, endPoint, intermediatePoint);

    return arcPoints
      .slice(1)
      .map((point, index) => (
        <SheetEdge
          key={index}
          segment={[arcPoints[index], point]}
          backgroundColor={color}
          pointerEvents="none"
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        />
      ));
  }
  return (
    <SheetEdge
      segment={[ring[ring.length - 1].point, intermediatePoint]}
      backgroundColor={color}
      getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
      pointerEvents={'none'} // Prevent edge on mouse pointer from capturing mouse events
    />
  );
};

function CompletedRings({
  state,
  dispatch,
  getPoint,
  setIsDragging,
  getPointInDomCoordinateSystem,
  getPointInPdfCoordinateSystem,
}: {
  state: SheetPolygonDrawingState;
  dispatch: Dispatch<SheetPolygonDrawingAction>;
  getPoint: (event: MouseEvent) => Point2;
  setIsDragging: (isDragging: boolean) => void;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
  getPointInPdfCoordinateSystem: (pointInDom: Point2) => Point2;
}) {
  const edgesForCompletedRings = useMemo(
    () =>
      Object.fromEntries(
        state.completedRings.map((ring) => [
          ring.id,
          generateIdsForEdges(ring.linearRing),
        ])
      ),
    [state.completedRings]
  );

  const invalidEdgesForCompletedRings = useMemo(() => {
    return getInvalidEdges(
      Object.values(edgesForCompletedRings).flat(),
      state.intersectionsForCompletedRings
    );
  }, [edgesForCompletedRings, state.intersectionsForCompletedRings]);

  const completedMultipolygon: MultiPolygon2 | null = useMemo(() => {
    const rings = state.completedRings.map((ring) => ring.linearRing);
    if (rings.length === 0) {
      return null;
    }
    // rings without intersections
    const validRings = rings
      .filter((ring) => {
        const edges = generateIdsForEdges(ring);
        return (
          getInvalidEdges(edges, state.intersectionsForCompletedRings)
            .length === 0
        );
      })
      .map((ring) => getPositions(ring));
    return createMultipolygon(validRings);
  }, [state.completedRings, state.intersectionsForCompletedRings]);

  const handleVertexDrag = (
    event: MouseEvent,
    pointId: string,
    ringId: string
  ) => {
    setIsDragging(true);
    const position = getPoint(event);
    dispatch({
      type: SheetPolygonDrawingActionType.MOVE_COMPLETE_RING_POINT,
      position,
      pointId,
      ringId,
    });
    if (event.type === 'pointerup') {
      setIsDragging(false);
    }
  };

  const isShiftPressed = isHotkeyPressed('shift');

  const handleEdgeDrag = (
    event: React.MouseEvent,
    edgeId: string,
    ringId: string
  ) => {
    setIsDragging(true);
    const startVector = getPointInPdfCoordinateSystem([
      event.clientX - event.movementX,
      event.clientY - event.movementY,
    ]);
    const translationVector = math.subtract(
      getPointInPdfCoordinateSystem([event.clientX, event.clientY]),
      startVector
    );
    const edgeIndex = edgesForCompletedRings[ringId].findIndex(
      (edge) => edge.id === edgeId
    );
    const startPointId = state.completedRings.find((ring) => ring.id === ringId)
      ?.linearRing[edgeIndex].id;
    invariant(startPointId, 'start point not found');

    let startPositionTranslation: Point2;
    let endPositionTranslation: Point2;

    if (!isShiftPressed) {
      const ring = state.completedRings.find(
        (ring) => ring.id === ringId
      )?.linearRing;

      invariant(ring, 'ring not found');

      const newRing = [...ring];

      const startPointIndex = newRing.findIndex(
        (point) => point.id === startPointId
      );

      // Get the start and end points of the dragged edge
      const startPoint = newRing[startPointIndex].point;
      const endPoint = newRing[(startPointIndex + 1) % newRing.length].point;

      // Get points connected to the start and end points
      const prevPoint =
        newRing[
          startPointIndex - 1 < 0 ? newRing.length - 2 : startPointIndex - 1
        ].point;
      const nextPoint =
        newRing[
          startPointIndex + 2 > newRing.length - 1 ? 1 : startPointIndex + 2
        ].point;

      // Calculate projection of draggingVector onto the lines defined by connected points
      startPositionTranslation = projectVectorOntoLine(
        translationVector,
        math.subtract(startPoint, prevPoint)
      );
      endPositionTranslation = projectVectorOntoLine(
        translationVector,
        math.subtract(nextPoint, endPoint)
      );
    } else {
      startPositionTranslation = translationVector;
      endPositionTranslation = translationVector;
    }

    dispatch({
      type: SheetPolygonDrawingActionType.MOVE_COMPLETE_RING_EDGE,
      startPositionTranslation,
      endPositionTranslation,
      startPointId,
      ringId,
    });

    if (event.type === 'pointerup') {
      setIsDragging(false);
    }
  };

  function projectVectorOntoLine(vector: Point2, line: Point2) {
    const normLine = math.norm(line);
    if (normLine === 0) return [0, 0] as Point2;
    const lineUnitVector = math.divide(line, normLine) as Point2;
    const projectionLength = math.dot(vector, lineUnitVector);
    return math.multiply(
      math.round(lineUnitVector, 1),
      projectionLength
    ) as Point2;
  }

  const handleEdgeDoubleClick = (
    event: React.MouseEvent,
    edgeId: string,
    ringId: string
  ) => {
    if (event.button !== 0) {
      return;
    }
    const newPoint = getPointInPdfCoordinateSystem([
      event.clientX,
      event.clientY,
    ]);
    const edgeIndex = edgesForCompletedRings[ringId].findIndex(
      (edge) => edge.id === edgeId
    );
    const startPointId = state.completedRings.find((ring) => ring.id === ringId)
      ?.linearRing[edgeIndex].id;
    invariant(startPointId, 'start point not found');

    dispatch({
      type: SheetPolygonDrawingActionType.SPLIT_COMPLETE_RING_EDGE,
      splitPosition: newPoint,
      startPointId,
      ringId,
    });
  };

  const { projectSteps } = useProtanProjectSteps();
  const { tenant } = useUserTenant();
  const roofOutlineInProgress =
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.RoofOutline
    )?.status === ProtanProjectStepStatusEnum.InProgress;

  return (
    <>
      {completedMultipolygon && (
        <>
          {tenant?.group === 'Protan' &&
            completedMultipolygon.polygons[0].exterior &&
            roofOutlineInProgress && (
              <AutoGeneratedRoof
                getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
                vertices={completedMultipolygon.polygons[0].exterior}
              ></AutoGeneratedRoof>
            )}
          <SheetPolygon
            multipolygon={completedMultipolygon}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            fill={'green.400'}
          />
        </>
      )}
      {Object.entries(edgesForCompletedRings).map(([ringId, edgesForRing]) =>
        edgesForRing.map(({ id: edgeId, segment }) => {
          const color = getEdgeColor(
            true,
            !invalidEdgesForCompletedRings.includes(edgeId)
          );
          return (
            <DraggableSheetEdge
              key={edgeId}
              onDoubleClick={(event) =>
                handleEdgeDoubleClick(event, edgeId, ringId)
              }
              onEdgeDrag={(event) => handleEdgeDrag(event, edgeId, ringId)}
              segment={segment}
              backgroundColor={color}
              getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            />
          );
        })
      )}

      {state.completedRings.map((ring) => {
        return ring.linearRing.map(({ point, id: pointId }, index) => {
          if (index === ring.linearRing.length - 1) {
            return null;
          }
          return (
            <DraggableVertex
              key={pointId}
              theme={index === 0 ? 'inverted' : 'default'}
              point={point}
              getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
              onDoubleClick={() => {
                dispatch({
                  type: SheetPolygonDrawingActionType.REMOVE_COMPLETE_RING_POINT,
                  ringId: ring.id,
                  pointId,
                });
              }}
              onVertexDrag={(event) =>
                handleVertexDrag(event, pointId, ring.id)
              }
            />
          );
        });
      })}
    </>
  );
}

const getEdgeColor = (isClosed?: boolean, isValid?: boolean) => {
  if (!isValid) {
    return 'red.400';
  } else if (isClosed) {
    return 'green.500';
  } else {
    return 'yellow.300';
  }
};
