import { useColorMode, IconButton, useToken } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

export default function ColorModeSwitcher(): React.ReactElement {
  const [gray600, white] = useToken('colors', ['gray.600', 'white']);
  const { colorMode, setColorMode } = useColorMode();

  if (colorMode === 'light') {
    return (
      <IconButton
        aria-label="use-dark-mode"
        variant={'ghost'}
        colorScheme="gray"
        icon={<FontAwesomeIcon icon={faMoon} color={gray600} />}
        onClick={() => void setColorMode('dark')}
      />
    );
  } else {
    return (
      <IconButton
        aria-label="use-light-mode"
        variant={'ghost'}
        colorScheme="gray"
        icon={<FontAwesomeIcon color={white} icon={faSun} />}
        onClick={() => void setColorMode('light')}
      />
    );
  }
}
