import { useCallback, useState } from 'react';
import { useViewer } from '../components/common/ForgeViewer';
import { useViewerMode } from '../components/common/viewer-mode';
import ShapeLabel from '../components/orderContractor/shapes/ShapeLabel';
import { useCameraChangedEvent } from '../components/orderContractor/shapes/util';
import { Point } from '../domain/geometry/geometric-types';
import { Polygon3Type } from '../gql/graphql';
import { hasDbIds } from '../services/viewer-services';

const ShapeLabelRenderer = () => {
  const {
    viewer,
    shapesManager: { renderedShapes },
    visibilityManager: { hiddenElements, isolatedElements, filteredElements },
    showLabels: { isShowingLabels },
  } = useViewer();

  const { viewerMode } = useViewerMode();

  const [cameraPosition, setCameraPosition] = useState({});

  useCameraChangedEvent(
    isShowingLabels && viewerMode === 'models' && renderedShapes
      ? (viewer as Autodesk.Viewing.GuiViewer3D)
      : undefined,
    useCallback(() => {
      setCameraPosition({});
    }, [])
  );

  function isShapeHidden(shapeUrn: string, shapeDbId: number) {
    return (
      hiddenElements[shapeUrn]?.includes(shapeDbId) ||
      (hasDbIds(isolatedElements) &&
        !isolatedElements[shapeUrn]?.includes(shapeDbId)) ||
      (hasDbIds(filteredElements) &&
        !filteredElements[shapeUrn]?.includes(shapeDbId))
    );
  }

  const polygonMidPoint = (multipolygon: Polygon3Type[]) => {
    let minX = Infinity,
      minY = Infinity,
      minZ = Infinity,
      maxX = -Infinity,
      maxY = -Infinity,
      maxZ = -Infinity;

    multipolygon.forEach((polygon) => {
      polygon.exterior.points.forEach(({ x, y, z }) => {
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        minZ = Math.min(minZ, z);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
        maxZ = Math.max(maxZ, z);
      });
    });

    return [(minX + maxX) / 2, (minY + maxY) / 2, (minZ + maxZ) / 2];
  };

  const renderShapeLabels = (viewer: Autodesk.Viewing.GuiViewer3D) =>
    Object.values(renderedShapes).map((shapes) =>
      shapes.map((shape) => {
        // Only render labels for shapes that are not hidden
        if (isShapeHidden(shape.urn, shape.dbId)) {
          return;
        }

        // Only render labels for polygon shapes for now
        if (shape.polygon?.multipolygon) {
          const shapeCentroid = polygonMidPoint(
            shape.polygon?.multipolygon.polygons
          );

          return (
            <ShapeLabel
              shape={shape}
              key={shape.dbId}
              position={shapeCentroid as Point}
              viewer={viewer}
              cameraPosition={cameraPosition}
            />
          );
        }
      })
    );

  return (
    <>
      {isShowingLabels && viewerMode === 'models' && renderedShapes && viewer
        ? renderShapeLabels(viewer)
        : undefined}
    </>
  );
};

export default ShapeLabelRenderer;
