import { ChakraProps, chakra } from '@chakra-ui/react';
import { useMemo } from 'react';
import {
  MultiPolygon2,
  Point2,
} from '../../../domain/geometry/geometric-types';
import {
  getPolygon2PathData,
  transformMultipolygonCoordinates,
} from '../shapes/util';

type PolygonProps = {
  multipolygon: MultiPolygon2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
  strokeWidth?: number;
  fillOpacity?: number;
} & ChakraProps;
export const SheetPolygon = ({
  multipolygon,
  getPointInDomCoordinateSystem,
  strokeWidth,
  fillOpacity,
  ...rest
}: PolygonProps) => {
  const pathData = useMemo(() => {
    return transformMultipolygonCoordinates(
      multipolygon,
      getPointInDomCoordinateSystem
    ).polygons.map((polygon) => getPolygon2PathData(polygon));
  }, [getPointInDomCoordinateSystem, multipolygon]);

  return (
    <chakra.svg
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      pointerEvents={'none'}
      opacity={strokeWidth ? 1 : 0.7}
      fillRule={'evenodd'}
      strokeWidth={strokeWidth}
      fillOpacity={fillOpacity}
    >
      {pathData.map((data, index) => (
        <chakra.path key={index} d={data} {...rest} />
      ))}
    </chakra.svg>
  );
};
