import { UseMutationExecute } from 'urql';
import {
  Diamond,
  KileTypeSKU,
} from '../components/orderContractor/pdf-shapes/SheetWedgeShapeDrawing';
import {
  BulkUpsertSparkelPropertiesInput,
  BulkUpsertSparkelPropertiesMutation,
  CreateSheetShapeMutation,
  Exact,
} from '../gql/graphql';

export const updateWedgeProps = async (
  result: CreateSheetShapeMutation,
  diamond: Diamond,
  kileType: KileTypeSKU,
  bulkCreateSparkelProps: UseMutationExecute<
    BulkUpsertSparkelPropertiesMutation,
    Exact<{
      input: BulkUpsertSparkelPropertiesInput;
    }>
  >,
  projectId: string
) => {
  if (diamond && result.createSheetShape?.sheetShape) {
    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Triangle1',
        thePropertyValue: diamond.triangle1 ? '1' : '0',
      },
    });
    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Triangle2',
        thePropertyValue: diamond.triangle2 ? '1' : '0',
      },
    });
    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Triangle3',
        thePropertyValue: diamond.triangle3 ? '1' : '0',
      },
    });
    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Triangle4',
        thePropertyValue: diamond.triangle4 ? '1' : '0',
      },
    });

    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'KileType',
        thePropertyValue: kileType,
      },
    });
  }
};
export const setProtanProperties = async (
  result: CreateSheetShapeMutation,
  roofHeight: string | undefined,
  qkast: string | undefined,
  orderId: string,
  fireRequirements: string | undefined,
  bulkCreateSparkelProps: UseMutationExecute<
    BulkUpsertSparkelPropertiesMutation,
    Exact<{
      input: BulkUpsertSparkelPropertiesInput;
    }>
  >,
  projectId: string
) => {
  if (qkast && result.createSheetShape?.sheetShape) {
    await bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'QKast',
        thePropertyValue: qkast,
      },
    });
  }
  if (result.createSheetShape?.sheetShape) {
    await bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'OrderId',
        thePropertyValue: orderId,
      },
    });
  }
  if (roofHeight && result.createSheetShape?.sheetShape) {
    await bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Roof height',
        thePropertyValue: roofHeight,
      },
    });
  }

  if (
    fireRequirements === 'Combustible' &&
    result.createSheetShape?.sheetShape
  ) {
    await bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Fire sectioning',
        thePropertyValue: '-0.72',
      },
    });
  }
};
