import GeometryFactory from 'jsts/org/locationtech/jts/geom/GeometryFactory';
import JstsLinearRing from 'jsts/org/locationtech/jts/geom/LinearRing';
import JstsMultiPolygon from 'jsts/org/locationtech/jts/geom/MultiPolygon';
import JstsPolygon from 'jsts/org/locationtech/jts/geom/Polygon';
import BufferOp from 'jsts/org/locationtech/jts/operation/buffer/BufferOp';
import TopologyPreservingSimplifier from 'jsts/org/locationtech/jts/simplify/TopologyPreservingSimplifier';
import * as math from 'mathjs';
import {
  calculatePlaneEquation,
  to3dMultiPolygon,
  toPlanarPoints,
} from '../../../../domain/geometry/algorithms/util/plane';
import {
  toJstsMultiPolygon,
  toJstsPolygon,
  toLinearRing2,
  toMultiPolygon2,
  union,
} from '../../../../domain/geometry/algorithms/util/polygon';
import { toVertex } from '../../../../domain/geometry/extract-forge-geometry';
import { getTriangles } from '../../../../domain/geometry/extract-triangles';
import {
  LinearRing2,
  MultiPolygon2,
  Plane,
  Triangle,
} from '../../../../domain/geometry/geometric-types';
import { HitTestResult, MagicShapeResult } from './magic-shape-types';

const shapeUnionBufferAmount = 1e-4;

export const getShapeByPlaneProjection = (
  viewer: Autodesk.Viewing.Viewer3D,
  hitTest: HitTestResult
): MagicShapeResult => {
  const hitTestPlane = getHitTestPlane(viewer, hitTest);
  const triangles = getTriangles(hitTest.model, hitTest.dbId).filter(
    (triangle) =>
      isSimilarPlanes(calculatePlaneEquation(triangle), hitTestPlane)
  );

  const multipolygons: MultiPolygon2[] = triangles.map((triangle) => {
    const ring = [...triangle, triangle[0]];
    const ring2 = toPlanarPoints(ring, hitTestPlane);
    return {
      polygons: [
        {
          exterior: ring2,
          interiors: [],
        },
      ],
    };
  });

  const union2d = union(multipolygons);

  // Buffer a tiny amount such that adjacent shapes will be merged when unioning
  const buffered = BufferOp.bufferOp(
    toJstsMultiPolygon(union2d),
    shapeUnionBufferAmount,
    0
  );
  // Simplify to remove duplicate points, points along the same line, etc.
  const simplifier = new TopologyPreservingSimplifier(buffered);
  simplifier.setDistanceTolerance(simplifierDistanceTolerance);
  const simplified = simplifier.getResultGeometry();
  const result3d = to3dMultiPolygon(toMultiPolygon2(simplified), hitTestPlane);
  const rings = result3d.polygons.flatMap((polygon) => [
    polygon.exterior,
    ...polygon.interiors,
  ]);
  return { rings, plane: hitTestPlane, hitTest };
};

const cosInv5Deg = 0.996;
const maxPlaneCoefficientDiff = 0.1;
const simplifierDistanceTolerance = 1e-2;

function getHitTestPlane(
  viewer: Autodesk.Viewing.Viewer3D,
  hitTest: HitTestResult
) {
  const currentFragId = hitTest.fragId;

  const renderProxy = viewer.impl.getRenderProxy(hitTest.model, currentFragId);

  // transformation from fragment space to WCS
  const matrix = renderProxy.matrixWorld;

  // geometry data of the fragment
  const geometry = renderProxy.geometry;

  // information of the fragment
  const attributes = geometry.attributes;

  // position array of the fragment
  const positions = geometry.vb ? geometry.vb : attributes.position.array;
  // unit range of the  vertices in the position array
  const stride = geometry.vb ? geometry.vbstride : 3;

  // vertices
  const vA = new THREE.Vector3();
  const vB = new THREE.Vector3();
  const vC = new THREE.Vector3();

  // index of the vertices of the specific viewer face
  const a = hitTest.face.a;
  const b = hitTest.face.b;
  const c = hitTest.face.c;

  // positions of  the vertices
  vA.fromArray(positions, a * stride);
  vB.fromArray(positions, b * stride);
  vC.fromArray(positions, c * stride);

  // transform to WCS in model space
  vA.applyMatrix4(matrix);
  vB.applyMatrix4(matrix);
  vC.applyMatrix4(matrix);

  const triangle: Triangle = [toVertex(vA), toVertex(vB), toVertex(vC)];
  return calculatePlaneEquation(triangle);
}

export const isSimilarPlanes = (plane1: Plane, plane2: Plane) => {
  const planeCoefficientDiff = math.abs(
    plane1.planeCoefficient - plane2.planeCoefficient
  );

  return (
    math.dot(plane1.unitNormal, plane2.unitNormal) > cosInv5Deg &&
    planeCoefficientDiff < maxPlaneCoefficientDiff
  );
};

export const mergeShapes = (ringsToMerge: LinearRing2[]): LinearRing2[] => {
  const jstsPolygons: JstsPolygon[] = ringsToMerge.map((ring) =>
    toJstsPolygon({ exterior: ring, interiors: [] })
  );
  const geometryFactory = new GeometryFactory();
  const geometryCollection =
    geometryFactory.createGeometryCollection(jstsPolygons);

  const union = BufferOp.bufferOp(geometryCollection, 0);
  const rings: JstsLinearRing[] = [];

  // Check the type of geometry returned by the union operation
  if (union instanceof JstsPolygon) {
    rings.push(union.getExteriorRing());
    for (let i = 0; i < union.getNumInteriorRing(); i++) {
      rings.push(union.getInteriorRingN(i));
    }
  } else if (union instanceof JstsMultiPolygon) {
    // Handle MultiPolygon case
    for (let i = 0; i < union.getNumGeometries(); i++) {
      const polygon = union.getGeometryN(i);
      rings.push(polygon.getExteriorRing());

      for (let j = 0; j < polygon.getNumInteriorRing(); j++) {
        rings.push(polygon.getInteriorRingN(j));
      }
    }
  }

  return rings.map((ring) => {
    const simplified = simplifyRing(ring);
    return toLinearRing2(simplified.getCoordinates());
  });
};

// Simplify to remove duplicate points, points on the same line etc
const simplifyRing = (ring: JstsLinearRing): JstsLinearRing => {
  const simplified = new TopologyPreservingSimplifier(ring);
  simplified.setDistanceTolerance(simplifierDistanceTolerance);
  return simplified.getResultGeometry();
};
