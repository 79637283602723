import { Box, Text, useColorMode } from '@chakra-ui/react';
import { Point2 } from '../../../domain/geometry/geometric-types';
import { kilerMap } from '../ProductChoices';
import { getPolylineMidpoint } from '../../../domain/geometry/algorithms/util/line-segment';
import { toPoints2 } from '../../../domain/geometry/algorithms/util/type-mapping';
import {
  GetSparkelPropertiesForProjectQuery,
  SheetScaleType,
  SheetShapeDeepFragment,
} from '../../../gql/graphql';
import { getDiamond, getWedgeFromLineShape } from './DiamondLabel';
import { onlyOneSide } from './kile-selector';

type WedgeLabelProps = {
  shape: SheetShapeDeepFragment;
  getPointInViewerCoordinateSystem: (pointInPdf: Point2) => Point2;
  propertyData: GetSparkelPropertiesForProjectQuery | undefined;
  calibration: SheetScaleType;
};

const WedgeLabel = ({
  shape,
  getPointInViewerCoordinateSystem,
  propertyData,
  calibration,
}: WedgeLabelProps) => {
  const { colorMode } = useColorMode();

  if (!shape.sheetShapeLine || !propertyData) {
    return null;
  }

  const diamond = getDiamond(propertyData, shape.dbId, shape.urn);
  const isOneSided = onlyOneSide(diamond);

  const wedgePoints = toPoints2(shape.sheetShapeLine.points);

  const position = getPointInViewerCoordinateSystem(
    getPolylineMidpoint(wedgePoints)
  );

  const wedge = getWedgeFromLineShape(
    shape,
    wedgePoints,
    propertyData,
    calibration
  );

  const wedgeLength = wedge ? kilerMap[wedge][0].length : 0;

  if (!wedgeLength) {
    return null;
  }

  const wedgeLabel = wedgeLength.toFixed(1) + ' wedge';

  if (isOneSided) {
    return (
      <Box
        position={'absolute'}
        top={position[1]}
        left={position[0]}
        transform="translate(-50%, -50%)"
        pointerEvents={'none'}
      >
        <Text
          color={'blue.500'}
          filter={`brightness(${colorMode === 'light' ? 40 : 160}%)`}
          fontWeight={'semibold'}
          fontSize={'2xs'}
        >
          {wedgeLabel}
        </Text>
      </Box>
    );
  }

  // For two-sided wedges, add a label to the 1/4 and 3/4 points
  const wedgeStart = wedgePoints[0];
  const wedgeEnd = wedgePoints[wedgePoints.length - 1];
  const wedgeStartMidpoint = [
    0.66 * wedgeStart[0] + 0.34 * wedgeEnd[0],
    0.66 * wedgeStart[1] + 0.34 * wedgeEnd[1],
  ] as Point2;
  const wedgeEndMidpoint = [
    0.34 * wedgeStart[0] + 0.66 * wedgeEnd[0],
    0.34 * wedgeStart[1] + 0.66 * wedgeEnd[1],
  ] as Point2;

  const wedgeStartPosition =
    getPointInViewerCoordinateSystem(wedgeStartMidpoint);
  const wedgeEndPosition = getPointInViewerCoordinateSystem(wedgeEndMidpoint);

  return (
    <>
      <Box
        position={'absolute'}
        top={wedgeStartPosition[1]}
        left={wedgeStartPosition[0]}
        transform="translate(-50%, -50%)"
        pointerEvents={'none'}
      >
        <Text
          color={'blue.500'}
          filter={`brightness(${colorMode === 'light' ? 40 : 160}%)`}
          fontWeight={'semibold'}
          fontSize={'2xs'}
        >
          {wedgeLabel}
        </Text>
      </Box>
      <Box
        position={'absolute'}
        top={wedgeEndPosition[1]}
        left={wedgeEndPosition[0]}
        transform="translate(-50%, -50%)"
        pointerEvents={'none'}
      >
        <Text
          color={'blue.500'}
          filter={`brightness(${colorMode === 'light' ? 40 : 160}%)`}
          fontWeight={'semibold'}
          fontSize={'2xs'}
        >
          {wedgeLabel}
        </Text>
      </Box>
    </>
  );
};

export default WedgeLabel;
