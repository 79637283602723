import {
  Box,
  Flex,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  faCheck,
  faCircleDot,
  faCopy,
  faDrawPolygon,
  faEllipsisH,
  faFolderMinus,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import invariant from 'tiny-invariant';
import { useMutation } from 'urql';
import { ReactComponent as PolylineIcon } from '../../assets/icons/draw-polyline.svg';
import {
  CreateShapeDocument,
  CreateShapeInput,
  DeleteShapeDocument,
  DeleteSheetShapeDocument,
  ShapeDeepFragment,
  UpdateShapeDocument,
} from '../../gql/graphql';
import { getShapeUrn } from '../../services/viewer/ShapesManager';
import { useSelection } from '../../services/viewer/selection';
import ConfirmDeletionModal from '../common/ConfirmDeletionModal';
import { useViewer } from '../common/ForgeViewer';
import SparkelIcon from '../common/icon/SparkelIcon';
import { useBimShapes } from '../../hooks/bim-shapes';
import { ShapeItemProps } from './ShapeItem';
import { ToggleShapeVisibilityButton } from './ToggleShapeVisibilityButton';

// eslint-disable-next-line complexity
export function BimShapeItem({
  shape,
  existingShapes,
  readOnly,
  onChange,
  index,
}: ShapeItemProps & {
  shape: ShapeDeepFragment;
}) {
  const {
    isOpen: showDeleteShapeModal,
    onOpen: onDeleteShapeModalOpen,
    onClose: onDeleteShapeModalClose,
  } = useDisclosure();

  const {
    isOpen: showDeleteSelectedShapesModal,
    onOpen: onDeleteSelectedShapesModalOpen,
    onClose: onDeleteSelectedShapesModalClose,
  } = useDisclosure();

  const {
    drawing: { isDrawing, shapeToEdit, editShape },
  } = useBimShapes();

  const {
    visibilityManager: { hide, hiddenElements, show },
  } = useViewer();

  const {
    deselectDbIds,
    selectedShapes: selected3dShapes,
    selectedSheetShapes,
  } = useSelection();

  const { register, handleSubmit } = useForm<{ name: string }>({
    defaultValues: { name: shape.name },
  });
  const toast = useToast();

  const [isRenaming, setIsRenaming] = useState(false);

  const [, createShapeMutation] = useMutation(CreateShapeDocument);
  const [updateShapeResult, updateShapeMutation] =
    useMutation(UpdateShapeDocument);
  const [, deleteShapeMutation] = useMutation(DeleteShapeDocument);
  const [, deleteSheetShapeMutation] = useMutation(DeleteSheetShapeDocument);

  const shapeUrn = shape.urn;
  const isHidden = useMemo(
    () =>
      shapeUrn in hiddenElements &&
      hiddenElements[shapeUrn].includes(shape.dbId),
    [hiddenElements, shape.dbId, shapeUrn]
  );
  const isEditingThisShape = shapeToEdit === shape.id;

  const selectShape = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isEditingThisShape) {
      return;
    }
    onChange(e, index);
  };

  const duplicateShape = async () => {
    const input = getShapeInput(shape, shape.name);

    const result = await createShapeMutation({
      input: input,
    });

    if (result.error) {
      console.error(result.error);
      toast({
        status: 'error',
        title: 'Error',
        description: 'An error occurred when duplicating shape.',
      });
    } else {
      toast({ title: 'Shape duplicated', status: 'success' });
    }
  };

  const removeFromFolder = async () => {
    const result = await updateShapeMutation({
      input: { id: shape.id, patch: { folder: null } },
    });

    if (result.error) {
      console.error(result.error);
      toast({
        status: 'error',
        title: 'Error',
        description: 'An error occurred when removing shape from folder.',
      });
    }
  };

  const deleteShape = useCallback(async () => {
    deselectDbIds({ [shapeUrn]: [shape.dbId] });

    const result = await deleteShapeMutation({ input: { id: shape.id } });

    if (result.error) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Could not delete shape. Please try again',
      });
      throw result.error;
    } else {
      toast({
        title: 'Shape successfully deleted',
        status: 'success',
      });
    }
  }, [
    deleteShapeMutation,
    deselectDbIds,
    shape.dbId,
    shape.id,
    shapeUrn,
    toast,
  ]);

  const selectedShapes = useMemo(
    () =>
      existingShapes.filter((shape) => {
        const urn = shape.urn;
        const selectedDbIds = selected3dShapes[urn] || selectedSheetShapes[urn];
        return selectedDbIds?.includes(shape.dbId);
      }),
    [existingShapes, selected3dShapes, selectedSheetShapes]
  );

  const deleteSelectedShapes = useCallback(async () => {
    const deleteTasks = selectedShapes.map((shape) => {
      if (shape.__typename === 'Shape') {
        return deleteShapeMutation({
          input: { id: shape.id },
        });
      } else if (shape.__typename === 'SheetShape') {
        return deleteSheetShapeMutation({
          input: { id: shape.id },
        });
      } else {
        throw new Error('Unknown shape type');
      }
    });

    try {
      await Promise.all(deleteTasks);
      toast({
        status: 'success',
        title: 'Shapes successfully deleted',
        position: 'top',
        duration: 2500,
      });
      deselectDbIds({ [shapeUrn]: selectedShapes.map((s) => s.dbId) });
    } catch (error) {
      console.error(error);
      toast({
        status: 'error',
        title: 'An error occurred deleting shapes',
        position: 'top',
      });
    }
  }, [
    deleteShapeMutation,
    deleteSheetShapeMutation,
    deselectDbIds,
    selectedShapes,
    shapeUrn,
    toast,
  ]);

  const onSubmitShapeName = handleSubmit(async ({ name }) => {
    const { error } = await updateShapeMutation({
      input: {
        id: shape.id,
        patch: {
          name,
        },
      },
    });
    if (error) {
      console.error(error);
      toast({
        title: 'An error occurred when renaming shape',
        status: 'error',
      });
    } else {
      setIsRenaming(false);
      toast({
        title: `Shape renamed to ${name}`,
        status: 'success',
      });
    }
  });

  const getShapeIcon = useCallback(() => {
    let shapeIcon;
    const iconColor: [string, string] = [
      isHidden ? 'gray.300' : 'gray.400',
      isHidden ? 'gray.600' : 'gray.500',
    ];

    if (shape.polygon) {
      shapeIcon = <SparkelIcon icon={faDrawPolygon} color={iconColor} />;
    } else if (shape.line) {
      shapeIcon = <SparkelIcon as={PolylineIcon} color={iconColor} />;
    } else if (shape.shapePoint) {
      shapeIcon = <SparkelIcon icon={faCircleDot} color={iconColor} />;
    }

    return <Box px={2}>{shapeIcon}</Box>;
  }, [isHidden, shape.line, shape.polygon, shape.shapePoint]);

  return (
    <>
      {isEditingThisShape ? (
        <Box w={6} display="block" textAlign="center">
          <SparkelIcon icon={faPen} size="sm" color="teal.400"></SparkelIcon>
        </Box>
      ) : (
        getShapeIcon()
      )}

      {isRenaming ? (
        <form onSubmit={onSubmitShapeName} id="rename-shape-form">
          <Input
            {...register('name', {
              required: true,
            })}
            aria-label="Rename shape"
            borderRadius={0}
            p={0}
            size={'sm'}
            height="auto"
            autoFocus={true}
          />
        </form>
      ) : (
        <Flex
          textAlign="left"
          flexGrow={1}
          py={1}
          my={-1}
          fontSize="sm"
          color={
            isEditingThisShape ? 'teal.400' : isHidden ? 'gray.400' : undefined
          }
          onClick={selectShape}
          cursor={!isEditingThisShape ? 'pointer' : undefined}
        >
          <Tooltip label={shape.name} openDelay={400}>
            <Text noOfLines={1} wordBreak={'break-all'} maxWidth={'100%'}>
              {isEditingThisShape ? `Editing ${shape.name}` : shape.name}
            </Text>
          </Tooltip>
        </Flex>
      )}
      {isRenaming ? (
        <IconButton
          aria-label="submit row"
          isRound
          size="xs"
          colorScheme="brand"
          type="submit"
          form="rename-shape-form"
          isLoading={updateShapeResult.fetching}
          icon={<SparkelIcon icon={faCheck} color={['gray.50', 'gray.700']} />}
        />
      ) : !readOnly ? (
        <HStack height={6} gap={0}>
          <Menu closeOnSelect={false} isLazy>
            {({ isOpen }) => (
              <>
                <MenuButton
                  aria-label={`Shape ${shape.name} options`}
                  opacity={isOpen ? 1 : 0}
                  position={isOpen ? 'initial' : 'absolute'}
                  _groupHover={{
                    opacity: isEditingThisShape ? 0 : 1,
                    position: isEditingThisShape ? 'absolute' : 'initial',
                  }}
                  _groupActive={{
                    display: isEditingThisShape ? 'none' : 'inline-block',
                    position: isEditingThisShape ? 'absolute' : 'initial',
                  }}
                  ml={'auto'}
                  isRound
                  as={IconButton}
                  variant={'ghost'}
                  colorScheme="gray"
                  size={'xs'}
                  maxWidth={16}
                >
                  <SparkelIcon icon={faEllipsisH} />
                </MenuButton>

                <Portal>
                  <MenuList maxW="xs" zIndex={2}>
                    <MenuItem
                      icon={<SparkelIcon icon={faPen} fixedWidth />}
                      onClick={() => setIsRenaming(true)}
                      closeOnSelect={true}
                    >
                      Rename shape
                    </MenuItem>
                    <MenuItem
                      icon={<SparkelIcon icon={faDrawPolygon} fixedWidth />}
                      onClick={() => editShape(shape.id)}
                      closeOnSelect={true}
                      isDisabled={isDrawing}
                    >
                      Edit shape
                    </MenuItem>
                    <MenuItem
                      icon={<SparkelIcon icon={faCopy} fixedWidth />}
                      closeOnSelect={true}
                      onClick={duplicateShape}
                    >
                      Duplicate shape
                    </MenuItem>
                    {shape.folder && (
                      <MenuItem
                        icon={<SparkelIcon icon={faFolderMinus} fixedWidth />}
                        onClick={removeFromFolder}
                      >
                        Remove from folder
                      </MenuItem>
                    )}
                    {selectedShapes?.length > 0 ? (
                      <MenuItem
                        icon={
                          <SparkelIcon
                            icon={faTrash}
                            color={'red.400'}
                            fixedWidth
                          />
                        }
                        textColor={'red.400'}
                        onClick={async (e) => {
                          e.stopPropagation();
                          onDeleteSelectedShapesModalOpen();
                        }}
                      >
                        Delete selected shapes
                      </MenuItem>
                    ) : (
                      <MenuItem
                        icon={
                          <SparkelIcon
                            icon={faTrash}
                            color={'red.400'}
                            fixedWidth
                          />
                        }
                        textColor={'red.400'}
                        onClick={async (e) => {
                          e.stopPropagation();
                          onDeleteShapeModalOpen();
                        }}
                      >
                        Delete
                      </MenuItem>
                    )}
                  </MenuList>
                </Portal>
              </>
            )}
          </Menu>
          <Box
            opacity={isHidden && !isEditingThisShape ? 1 : 0}
            display={'inline-block'}
            _groupHover={{
              opacity: isEditingThisShape ? 0 : 1,
            }}
            _groupActive={{
              opacity: isEditingThisShape ? 0 : 1,
            }}
          >
            <ToggleShapeVisibilityButton
              isHidden={isHidden}
              hideShape={() =>
                hide({
                  [shapeUrn]:
                    selectedShapes.length > 0
                      ? selectedShapes.map(
                          (selectedShape) => selectedShape.dbId
                        )
                      : [shape.dbId],
                })
              }
              showShape={() =>
                show({
                  [shapeUrn]:
                    selectedShapes.length > 0
                      ? selectedShapes.map(
                          (selectedShape) => selectedShape.dbId
                        )
                      : [shape.dbId],
                })
              }
            />
          </Box>
        </HStack>
      ) : null}

      <ConfirmDeletionModal
        onConfirm={deleteShape}
        isOpen={showDeleteShapeModal}
        onClose={onDeleteShapeModalClose}
      ></ConfirmDeletionModal>
      <ConfirmDeletionModal
        onConfirm={deleteSelectedShapes}
        isOpen={showDeleteSelectedShapesModal}
        onClose={onDeleteSelectedShapesModalClose}
        itemsToDelete={selectedShapes.map((e) => e.name)}
      />
    </>
  );
}

function getShapeInput(
  shape: ShapeDeepFragment,
  name: string
): CreateShapeInput {
  const geometry = getGeometryInput(shape);
  return {
    shape: {
      name,
      urn: getShapeUrn(shape.projectId),
      projectId: shape.projectId,
      ...geometry,
    },
  };
}

function getGeometryInput(shape: ShapeDeepFragment) {
  if (shape.polygon) {
    return {
      polygon: {
        create: [
          {
            unitScale: shape.polygon.unitScale,
            plane: shape.polygon.plane,
            multipolygon: shape.polygon.multipolygon,
          },
        ],
      },
    };
  } else if (shape.shapePoint) {
    return {
      shapePoint: {
        create: [
          {
            point: shape.shapePoint.point,
          },
        ],
      },
    };
  } else {
    invariant(shape.line, 'Shape does not have geometry');
    return {
      line: {
        create: [
          {
            unitScale: shape.line.unitScale,
            points: shape.line.points,
          },
        ],
      },
    };
  }
}
